/* eslint-disable consistent-return */
import { Auth } from "@aws-amplify/auth";
import { notification } from "antd";

import history from "./history";

let user = null;

const authenticateCurrentUser = async () => {
  const currentUser = await Auth.currentAuthenticatedUser();
  return currentUser;
};

const userSignOut = async () => {
  const isSignedOut = await Auth.signOut();
  clearTokens();
  return isSignedOut;
};

const normalizeUsername = (username) => username?.toLowerCase();

const userSignIn = async (username, password) => {
  const userData = await Auth.signIn(normalizeUsername(username), password);
  user = userData;
  return userData;
};

const userSignUp = async (username, password, attributes) => {
  const userData = await Auth.signUp({
    username: normalizeUsername(username),
    password,
    attributes: {
      "custom:first_name": attributes.firstName,
      "custom:last_name": attributes.lastName,
    },
  });
  return userData;
};

const getJwtToken = async () => {
  const sessionBefore = await Auth.currentSession();
  if (sessionBefore) {
    localStorage.setItem(
      "ap_idToken",
      sessionBefore.getIdToken().getJwtToken()
    );
    localStorage.setItem("ap_rToken", sessionBefore.refreshToken.token);
    return sessionBefore.getIdToken().getJwtToken();
  } else {
    localStorage.clear();
    history.push("/login");
    return null;
  }
};

const setTokens = async (data) => {
  try {
    const idToken = data.signInUserSession.idToken.jwtToken;
    const refreshToken = data?.signInUserSession.refreshToken.token;
    localStorage.setItem("ap_idToken", idToken);
    localStorage.setItem("ap_rToken", refreshToken);
    // setTimeout()
    history.push("/data360-home");
  } catch (error) {
    // console.debug('error setting token', error);
  }
};

const clearTokens = async () => {
  try {
    localStorage.clear("ap_idToken");
    localStorage.clear("ap_rToken");
    // history.push('/login');
  } catch (error) {
    // console.debug('error setting token', error);
  }
};

export const getToken = () => {
  // getJwtToken();
  return localStorage.getItem("ap_idToken")
    ? localStorage.getItem("ap_idToken")
    : null;
};
// export const getToken = () => {getJwtToken()};

export const getAuthorizationHeader = () => `Bearer ${getToken()}`;

const forgotPassword = (email) => {
  try {
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(email)
        .then((data) => {
          notification.destroy();
          notification.success({
            message: `Verification code sent to ${data.CodeDeliveryDetails.Destination}`,
          });
          resolve(data.CodeDeliveryDetails.Destination);
        })
        .catch((err) => {
          notification.destroy();
          if (err.message === "Network error") {
            notification.error({
              message: err.message,
            });
          } else if (
            err.message ===
            "Exceeded daily email limit for the operation or the account. If a higher limit is required, please configure your user pool to use your own Amazon SES configuration for sending email."
          ) {
            notification.error({
              message: "Server is busy. Please try again later.",
            });
          } else {
            notification.error({
              message: "User not found",
            });
          }
          reject(err);
        });
    });
  } catch (error) {
    notification.destroy();
    notification.error({
      message: "Unable to fetch user",
    });
  }
};

const forgotPasswordSubmit = (email, code, newPass) =>
  new Promise((resolve, reject) => {
    Auth.forgotPasswordSubmit(email, code, newPass)
      .then(() => {
        notification.destroy();
        notification.success({
          message: "Password changed successfully",
        });
        history.push("./login");
        resolve("");
      })
      .catch((err) => {
        notification.destroy();
        if (
          err.code === "InvalidParameterException" ||
          err.code === "InvalidPasswordException"
        ) {
          notification.error({
            message:
              "Passwords must be at least 8 characters in length including one number, special character, uppercase and lowercase.",
          });
        } else if (
          err.code === "CodeMismatchException" ||
          err.code === "ExpiredCodeException"
        ) {
          notification.error({
            message: "MisMatch in verification code ",
          });
        } else if (err.code === "UserNotFoundException") {
          notification.error({
            message: "User not Found ",
          });
        } else if (err.message === "Network error") {
          notification.error({
            message: err.message,
          });
        } else {
          notification.error({
            message: err.message,
          });
          reject(err);
        }
      });
  });

const resetPasswordSubmit = (newPass, fullName) =>
  new Promise((resolve, reject) => {
    Auth.completeNewPassword(user, newPass, { name: fullName })
      .then(() => {
        if (user) {
          notification.destroy();
          notification.success({
            message: "Password changed successfully",
          });
          history.push("./login");
          resolve("");
        }
      })
      .catch((err) => {
        notification.destroy();
        if (
          err.code === "InvalidParameterException" ||
          err.code === "InvalidPasswordException"
        ) {
          notification.error({
            message:
              "Passwords must be at least 8 characters in length including one number, special character, uppercase and lowercase.",
          });
        } else if (
          err.code === "CodeMismatchException" ||
          err.code === "ExpiredCodeException"
        ) {
          notification.error({
            message: "MisMatch in verification code ",
          });
        } else if (err.code === "UserNotFoundException") {
          notification.error({
            message: "User not Found ",
          });
        } else if (err.message === "Network error") {
          notification.error({
            message: err.message,
          });
        } else {
          notification.error({
            message: err.message,
          });
          reject(err);
        }
      });
  });

const resendVerifyLink = (username) =>
  new Promise((resolve, reject) => {
    Auth.resendSignUp(username)
      .then((data) => {
        notification.destroy();
        notification.success({
          message: `Verification link sent to ${data.CodeDeliveryDetails.Destination}`,
        });
        resolve("");
      })
      .catch((err) => {
        notification.destroy();
        if (err.message === "Network error") {
          notification.error({
            message: err.message,
          });
        } else {
          notification.error({
            message: "Please try signing up again.",
          });
        }
        reject(err);
      });
  });

const changeProfilePassword = (oldPassword, newPassword) =>
  new Promise((resolve) => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then((data) => {
        if (data) {
          notification.destroy();
          notification.success({
            message: "Passoword Changed Successfully.",
          });
          resolve("");
        }
      })
      .catch((err) => {
        if (err.message === "Incorrect username or password.") {
          notification.warn({
            message: "Provided Incorrect Current Password.",
            description: "Please try again providing correct passowrd.",
          });
        } else if (
          err.message === "Attempt limit exceeded, please try after some time."
        ) {
          notification.warn({
            message: "Attempt limit exceeded, please try after some time.",
          });
        } else if (err.message === "Network error") {
          notification.error({
            message: err.message,
          });
        } else {
          notification.Warn({
            message: "Server is busy. Please try again later.",
          });
        }
      });
  });

export {
  authenticateCurrentUser,
  userSignOut,
  getJwtToken,
  userSignIn,
  clearTokens,
  setTokens,
  forgotPasswordSubmit,
  forgotPassword,
  resetPasswordSubmit,
  userSignUp,
  resendVerifyLink,
  changeProfilePassword,
};
