const PROJECT_LOADER_START = "@@project/startLoader";
const SET_PROJECT_LIST = "@@project/setProjectList";
const PROJECT_LOADER_STOP = "@@project/stopLoader";
const SET_SELECTED_PROJECT = "@@project/setSelectedProject";
const FETCH_PROJECT_SAGA = "@@project/fetchProject";
const FETCH_PIPE_LENGTH = "@@project/fetchPipeLength";

export {
  PROJECT_LOADER_START,
  SET_PROJECT_LIST,
  PROJECT_LOADER_STOP,
  SET_SELECTED_PROJECT,
  FETCH_PROJECT_SAGA,
  FETCH_PIPE_LENGTH,
};
