import {
  STATUS_CALCULATION_LOADER_START,
  STATUS_CALCULATION_LOADER_STOP,
  SET_STATUS_CALCULATION_LIST,
} from "./types";

const initialState = {
  isStatusLoading: false,
  statusList: [],
};

const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATUS_CALCULATION_LOADER_START: {
      return {
        ...state,
        isStatusLoading: true,
      };
    }

    case SET_STATUS_CALCULATION_LIST: {
      return {
        ...state,
        statusList: action.payload,
      };
    }

    case STATUS_CALCULATION_LOADER_STOP: {
      return {
        ...state,
        isStatusLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default statusReducer;
