import {
  SET_ZONEVIEW_LIST,
  ZONEVIEW_LOADER_START,
  ZONEVIEW_LOADER_STOP,
} from "./type";

const startZoneviewLoader = () => ({
  type: ZONEVIEW_LOADER_START,
});

const stopZoneviewLoader = () => ({
  type: ZONEVIEW_LOADER_STOP,
});

const setZoneviewList = (payload) => ({
  type: SET_ZONEVIEW_LIST,
  payload,
});

export { startZoneviewLoader, stopZoneviewLoader, setZoneviewList };
