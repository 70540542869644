import {
  SWITCH_THEME,
  TOGGLE_PROJECT_DETAILS,
  TOGGLE_PROJECT_LIST,
  TOGGLE_SIDEBAR,
  TOGGLE_THEME,
} from "./types";

const switchTheme = (payload) => ({
  type: SWITCH_THEME,
  payload,
});

const toggleTheme = () => ({
  type: TOGGLE_THEME,
});

const toggleSideBar = (payload) => ({
  type: TOGGLE_SIDEBAR,
  payload,
});

const toggleProjectList = (payload) => ({
  type: TOGGLE_PROJECT_LIST,
  payload,
});

const toggleProjectDetails = (payload) => ({
  type: TOGGLE_PROJECT_DETAILS,
  payload,
});

export {
  switchTheme,
  toggleTheme,
  toggleSideBar,
  toggleProjectList,
  toggleProjectDetails,
};
