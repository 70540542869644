import {
  SLOGS_LOADER_START,
  SLOGS_LOADER_STOP,
  SET_SLOGS_LIST,
  FILTERED_TABLE_DATA,
} from "./types";

const initialState = {
  isSlogsLoading: false,
  slogsList: [],
  filteredTableData: [],
};

const slogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SLOGS_LOADER_START: {
      return {
        ...state,
        isSlogsLoading: true,
      };
    }

    case SET_SLOGS_LIST:
      return {
        ...state,
        slogsList: action.payload.initialLoad
          ? action.payload.data
          : [...state.slogsList, ...action.payload.data],
      };

    case SLOGS_LOADER_STOP: {
      return {
        ...state,
        isSlogsLoading: false,
      };
    }

    case FILTERED_TABLE_DATA: {
      return {
        ...state,
        filteredTableData: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default slogsReducer;
