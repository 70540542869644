import mapboxgl from "mapbox-gl";
import React, { useRef, useEffect, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import "../global-styles/antd-custom.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { commonConstants } from "../constants";
import CommonLoader from "./Loaders/loader";
import { colorPalette } from "../constants/commonConstants";
// import { Switch } from "antd";
import { startSlogsLoader } from "../redux/sLogdata/actions";
import { FETCH_SLOGS_SAGA } from "../redux/sLogdata/types";
const AVG_EARTH_RADIUS_M = 6371008.8;
let MAX = 0;

//Moving this to .env throws error
mapboxgl.accessToken =
  "pk.eyJ1IjoibWFuanVuYXRoLWxvZ3ljZW50IiwiYSI6ImNseWd1NGl3ODBibGwybHNhdmFnNjN2YnAifQ._3p7J3DCBjxNHUFDxNQQqQ";

const MapBox = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  // const [isPopupShown, setIsPopupShown] = useState(false);
  // const handleSwitchChange = () => {
  //   setIsPopupShown((isPopupShown) => !isPopupShown);  //LEAVE THIS FOR FUTURE USAGE
  // };
  const [mapView, setMapView] = useState(commonConstants.mapViewModes.street);

  const toggleSideBarState = useSelector(
    (state) => state?.switchThemeReducer?.toggleSideBar
  );

  const toggleProjectListState = useSelector(
    (state) => state?.switchThemeReducer?.toggleProjectList
  );

  const toggleProjectDetailsState = useSelector(
    (state) => state?.switchThemeReducer?.toggleProjectDetails
  );

  const isMapLoading = useSelector(
    (state) => state?.slogReducer?.isSlogsLoading
  );
  const selectedProject = useSelector(
    (state) => state?.projectReducer?.selectedProject
  );

  useEffect(() => {
    const params = {
      project_id: selectedProject.project_id,
      project_name: selectedProject.project_name,
    };
    dispatch(startSlogsLoader());
    dispatch({ type: FETCH_SLOGS_SAGA, payload: params });
  }, [
    selectedProject,
    toggleSideBarState,
    toggleProjectListState,
    toggleProjectDetailsState,
  ]);

  // Function to calculate haversine distance between two points
  const haversine = (point1, point2) => {
    const { lat: lat1, lng: lon1 } = point1;
    const { lat: lat2, lng: lon2 } = point2;

    const dLat = toRadians(lat2 - lat1);
    const dLon = toRadians(lon2 - lon1);
    const radLat1 = toRadians(lat1);
    const radLat2 = toRadians(lat2);

    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) *
        Math.sin(dLon / 2) *
        Math.cos(radLat1) *
        Math.cos(radLat2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    if (AVG_EARTH_RADIUS_M * c > MAX) {
      MAX = AVG_EARTH_RADIUS_M * c;
    }
    return AVG_EARTH_RADIUS_M * c;
  };

  const GetClusterMedian = (clusterpoint) => {
    if (Array.isArray(clusterpoint)) {
      let latSum = 0;
      let lonSum = 0;
      clusterpoint.forEach((point) => {
        latSum += Number(point.Latitude);
        lonSum += Number(point.Longitude);
      });
      return {
        lat: latSum / clusterpoint.length,
        lng: lonSum / clusterpoint.length,
      };
    } else {
      return {
        latitude: clusterpoint.Latitude,
        longitude: clusterpoint.Longitude,
      };
    }
  };

  const toRadians = (degrees) => {
    return degrees * (Math.PI / 180);
  };

  const sLogsFromReducer = useSelector(
    (state) => state?.slogReducer?.slogsList
  );
  let sLogs = [];
  if (sLogsFromReducer && Array.isArray(sLogsFromReducer)) {
    sLogs = sLogsFromReducer.flatMap((ite) => ite.slogs);
  }
  sLogs.sort((a, b) => {
    let numA = Number(a.weld_number);
    let numB = Number(b.weld_number);

    if (isNaN(numA)) numA = Infinity;
    if (isNaN(numB)) numB = Infinity;

    return numA - numB;
  });

  //Extracting only id, weld_number and gps from the s_logs
  const sLogsMapped = sLogs.map((log) => {
    let gpsData;
    try {
      gpsData = log.gps ? JSON.parse(log.gps) : null;
    } catch (e) {
      console.error("Error parsing GPS Data", e);
      gpsData = null;
    }
    let mapped = {
      id: log.s_logs_id,
      weldID: Number(log.weld_number),
      gps: gpsData,
    };
    return mapped;
  });

  //Grouping s_logs
  let groupedSLogs = sLogsMapped.reduce((acc, obj) => {
    const key = obj.weldID;
    if (!acc[key]) {
      //Error handling (optional)
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

  const sLogsValuesGroup = Object.values(groupedSLogs);

  // Grouping each weld in a cluster to a median
  const gpsPlotData = sLogsValuesGroup
    ?.map((cluster) => {
      let clusterGps = [];
      cluster.forEach((ite) => {
        if (ite.gps && ite.gps.Latitude && ite.gps.Longitude) {
          clusterGps.push(ite.gps);
        }
      });

      if (clusterGps.length > 0) {
        const weldMedian = GetClusterMedian(clusterGps);
        return {
          weldID: cluster[0].weldID,
          gps: weldMedian,
          clusterLength: cluster.length,
        };
      } else {
        return null;
      }
    })
    .filter((cluster) => cluster !== null);

  // Assigning distance
  for (let i = 0; i < gpsPlotData.length; i++) {
    let distance = 0;
    if (i === 0) {
      gpsPlotData[i] = { ...gpsPlotData[i], distance: 0 };
      continue;
    }
    distance = haversine(gpsPlotData[i - 1].gps, gpsPlotData[i].gps) * 3.28;
    gpsPlotData[i] = { ...gpsPlotData[i], distance: distance };
  }

  const handleMapView = (viewType) => {
    viewType === commonConstants.mapViewModes.satellite
      ? setMapView(commonConstants.mapViewModes.street)
      : setMapView(commonConstants.mapViewModes.satellite);
  };
  const switchHtml = `
      <button id="street-view">Street</button>
      <button id="satellite-view">Satellite</button>
  `;
  class CustomControl {
    onAdd(map) {
      this._map = map;
      this._container = document.createElement("div");
      this._container.className = "parent-toggle";
      this._container.innerHTML = switchHtml;
      this._container
        .querySelector("#street-view")
        .addEventListener("click", () =>
          handleMapView(commonConstants.mapViewModes.satellite)
        );
      this._container
        .querySelector("#satellite-view")
        .addEventListener("click", () =>
          handleMapView(commonConstants.mapViewModes.street)
        );

      return this._container;
    }

    onRemove() {
      this._container.parentNode.removeChild(this._container);
      this._map = undefined;
    }
  }

  const mapContainer = useRef(null);

  useEffect(() => {
    if (gpsPlotData.length && mapContainer.current) {
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        style:
          mapView === commonConstants.mapViewModes.street
            ? "mapbox://styles/mapbox/standard"
            : "mapbox://styles/mapbox/standard-satellite",
        center: gpsPlotData.length
          ? [gpsPlotData?.[0].gps.lng, gpsPlotData?.[0].gps.lat]
          : ["0", "0"],
        zoom: 17,
        attributionControl: false,
      });

      const nav = new mapboxgl.NavigationControl();
      map.addControl(nav, "bottom-right");
      map.addControl(new CustomControl(), "bottom-left");

      gpsPlotData.forEach((mark, index) => {
        const popup = new mapboxgl.Popup({
          offset: 25,
          anchor: "left",
          closeOnClick: false,
        }).setText(
          `Weld ID : ${mark.weldID} (${
            mark.clusterLength
          }) \n Distance : ${mark.distance.toFixed(2)}`
        );

        new mapboxgl.Marker({
          color: colorPalette[index % colorPalette.length],
        })
          .setLngLat([mark.gps.lng, mark.gps.lat])
          .setPopup(popup)
          .addTo(map);

        // if (isPopupShown) {
        //   popup.addTo(map);
        // } else {
        //   popup.remove();
        // }
      });

      return () => {
        map.remove();
        map.off("click");
      };
    }
  }, [
    gpsPlotData,
    colorPalette,
    /* isPopupShown, */ toggleSideBarState,
    toggleProjectListState,
    toggleProjectDetailsState,
  ]);

  const getScrollHeight = () => {
    const screenHeight = window.innerHeight;

    if (screenHeight >= 832 && screenHeight <= 956) {
      if (toggleProjectListState && toggleProjectDetailsState) {
        return "540px";
      } else if (!toggleProjectListState && !toggleProjectDetailsState) {
        return "730px";
      } else if (!toggleProjectListState && toggleProjectDetailsState) {
        return "660px";
      } else if (toggleProjectListState && !toggleProjectDetailsState) {
        return "610px";
      }
    } else {
      if (toggleProjectListState && toggleProjectDetailsState) {
        return "330px";
      } else if (!toggleProjectListState && !toggleProjectDetailsState) {
        return "530px";
      } else if (!toggleProjectListState && toggleProjectDetailsState) {
        return "450px";
      } else if (toggleProjectListState && !toggleProjectDetailsState) {
        return "400px";
      }
      return "400px";
    }
  };

  return (
    <>
      {isMapLoading ? (
        <div style={{ marginTop: "50px", paddingTop: "50px" }}>
          <CommonLoader />
        </div>
      ) : (
        <>
          {gpsPlotData.length ? (
            <div
              className="map-parent"
              style={{ height: `${getScrollHeight()}` }}
            >
              {/* <div className="map-switch">  //LEAVE THIS FOR FUTURE USAGE
                <Switch
                  checked={isPopupShown}
                  checkedChildren={t("common.hideDetails")}
                  unCheckedChildren={t("common.showDetails")}
                  onChange={handleSwitchChange}
                />
              </div> */}
              <div ref={mapContainer} className="map-box-container" />
            </div>
          ) : (
            <div>{t("sLogData.sLogsGpsError")}</div>
          )}
        </>
      )}
    </>
  );
};

export default MapBox;
