import {
  STATUS_CALCULATION_LOADER_START,
  STATUS_CALCULATION_LOADER_STOP,
  SET_STATUS_CALCULATION_LIST,
} from "./types";

const startStatusLoader = () => ({
  type: STATUS_CALCULATION_LOADER_START,
});

const stopStatusLoader = () => ({
  type: STATUS_CALCULATION_LOADER_STOP,
});

const setStatusList = (payload) => ({
  type: SET_STATUS_CALCULATION_LIST,
  payload,
});

export { startStatusLoader, stopStatusLoader, setStatusList };
