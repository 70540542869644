import {
  PROJECT_LOADER_START,
  PROJECT_LOADER_STOP,
  SET_PROJECT_LIST,
} from "./types";

const startProjectLoader = () => ({
  type: PROJECT_LOADER_START,
});

const setProjectList = (payload) => ({
  type: SET_PROJECT_LIST,
  payload,
});

const stopProjectLoader = () => ({
  type: PROJECT_LOADER_STOP,
});

export { startProjectLoader, setProjectList, stopProjectLoader };
