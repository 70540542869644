import {
  SLOGS_LOADER_REPORT_START,
  SLOGS_LOADER_REPORT_STOP,
  SET_SLOGS_REPORT_LIST,
  SET_ZONE_REPORT_LIST,
  SET_PASS_REPORT_LIST,
} from "./types";

const initialState = {
  isSlogReportLoading: false,
  slogReportList: [],
  zoneReportList: [],
  passReportList: [],
};

const slogsReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case SLOGS_LOADER_REPORT_START: {
      return {
        ...state,
        isSlogReportLoading: true,
      };
    }

    case SET_SLOGS_REPORT_LIST: {
      return {
        ...state,
        slogReportList: action.payload,
      };
    }
    case SET_ZONE_REPORT_LIST: {
      return {
        ...state,
        zoneReportList: action.payload,
      };
    }
    case SET_PASS_REPORT_LIST: {
      return {
        ...state,
        passReportList: action.payload,
      };
    }

    case SLOGS_LOADER_REPORT_STOP: {
      return {
        ...state,
        isSlogReportLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default slogsReportReducer;
