import {
  PROJECT_LOADER_START,
  PROJECT_LOADER_STOP,
  SET_PROJECT_LIST,
  SET_SELECTED_PROJECT,
} from "./types";

const initialState = {
  isProjectLoading: false,
  projectList: [],
  selectedProject: {},
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_LOADER_START: {
      return {
        ...state,
        isProjectLoading: true,
      };
    }

    case SET_PROJECT_LIST: {
      return {
        ...state,
        projectList: action.payload,
      };
    }

    case PROJECT_LOADER_STOP: {
      return {
        ...state,
        isProjectLoading: false,
      };
    }

    case SET_SELECTED_PROJECT: {
      return {
        ...state,
        selectedProject: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default projectReducer;
