const FETCH_TLOGS_SAGA = "@@tlogs/fetchTlogsSaga";
const SET_TLOGS_LIST = "@@tlogs/setTlogsList";
const TLOGS_LOADER_START = "@@tlogs/startLoader";
const TLOGS_LOADER_STOP = "@@tlogs/stopLoader";

export {
  FETCH_TLOGS_SAGA,
  SET_TLOGS_LIST,
  TLOGS_LOADER_START,
  TLOGS_LOADER_STOP,
};
