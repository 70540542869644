import { notification } from "antd";
import { put, call, all, takeLatest } from "redux-saga/effects";

import {
  setProjectList,
  startProjectLoader,
  stopProjectLoader,
} from "./action.js";
import { FETCH_PROJECT_SAGA } from "./types.js";
import { BASE_URL } from "../../config.js";
import fetch from "../../services/fetch";

function* fetchProjectSaga() {
  try {
    yield put(startProjectLoader());

    const res = yield fetch(`${BASE_URL}/v1/internal/projects`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });
    if (res.status === 403) {
      throw new Error("Unauthorized");
    }
    const response = yield res.json();

    switch (response.status) {
      case 200:
        {
          yield put(setProjectList(response?.data?.recordList));
        }
        break;
      case 410:
        {
          notification.warn({
            message: response?.message,
            duration: 6,
          });
        }
        break;
      default: {
        notification.error({
          message: "Something went wrong.",
        });
      }
    }

    yield put(stopProjectLoader());
  } catch (error) {
    console.log(error);
    notification.error({
      message: { error },
    });
  }
}

function* projectWatcher() {
  yield takeLatest(FETCH_PROJECT_SAGA, fetchProjectSaga);
}

function* projectSagas() {
  yield all([call(projectWatcher)]);
}

export default projectSagas;
