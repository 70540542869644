import { notification } from "antd";
import { put, call, all, takeLatest } from "redux-saga/effects";
import { setStatusList, startStatusLoader, stopStatusLoader } from "./actions";
import { FETCH_STATUS_CALCULATION_SAGA } from "./types";
import { BASE_URL } from "../../config";
import fetch from "../../services/fetch";

function* fetchStatusSaga(action) {
  try {
    yield put(startStatusLoader());

    const { project_name, project_id, limit, skip } = action.payload;

    const url = new URL(`${BASE_URL}/v1/exposed/table-limit`);
    if (project_id) url.searchParams.append("project_id", project_id);
    if (project_name) url.searchParams.append("project_name", project_name);
    if (skip) url.searchParams.append("skip", skip);
    if (limit) url.searchParams.append("limit", limit);

    const res = yield fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });

    if (res.status === 403) {
      throw new Error("Unauthorized");
    }

    const response = yield res.json();

    switch (response.status) {
      case 200:
        yield put(setStatusList(response?.data));
        break;
      case 410:
        notification.warn({
          message: response?.message,
          duration: 6,
        });
        break;
      default:
        notification.error({
          message: "Something went wrong.",
        });
    }

    yield put(stopStatusLoader());
  } catch (error) {
    // console.log(error);
    notification.error({
      message: error.message || "Failed to fetch status.",
    });
    yield put(stopStatusLoader());
  }
}
function* statusWatcher() {
  yield takeLatest(FETCH_STATUS_CALCULATION_SAGA, fetchStatusSaga);
}

function* statusSagas() {
  yield all([call(statusWatcher)]);
}

export default statusSagas;
