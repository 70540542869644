import { CloseCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const EmailForm = ({ emails, setEmails, weldNumbers }) => {
  const [emailForm] = Form.useForm();

  const { t } = useTranslation();

  const { Item } = Form;

  const validateEmail = (_, value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!value) {
      return Promise.resolve("Email is optional.");
    } else if (!emailRegex.test(value)) {
      return Promise.reject("Please enter a valid email address.");
    } else if (emails.includes(value)) {
      return Promise.reject("Please enter a non-duplicate email address.");
    }
    return Promise.resolve();
  };

  const handleInputEnter = () => {
    emailForm
      .validateFields()
      .then(() => {
        const newEmail = emailForm.getFieldValue("additionalEmails").trim();
        if (newEmail !== "" && !emails.includes(newEmail)) {
          setEmails([newEmail, ...emails]);
          emailForm.setFieldsValue({ additionalEmails: "" });
        }
      })
      .catch((e) =>
        console.error("Invalid Email : ", e.values.additionalEmails)
      );
  };
  const handleTagClose = (index) => {
    const updatedEmails = emails.filter((_, i) => i !== index);
    setEmails(updatedEmails);
  };
  return (
    <div className="email-popup-text">
      <ol>
        {emails.map((email, index) => (
          <li key={email}>
            <Button key={index} className="modal-email-button" ghost={false}>
              <div>{email}</div>
            </Button>
            <Button
              onClick={() => handleTagClose(index)}
              className="email-cancel-button"
            >
              <CloseCircleFilled />
            </Button>
          </li>
        ))}
      </ol>

      <Form form={emailForm} className="email-input-and-send">
        <Item name="additionalEmails" rules={[{ validator: validateEmail }]}>
          <Input
            placeholder={t("Enter email(s)")}
            onPressEnter={handleInputEnter}
            disabled={emails.length > 4}
          />
        </Item>
        <Item>
          <PlusCircleFilled onClick={handleInputEnter} />
        </Item>
      </Form>

      {weldNumbers?.length && weldNumbers.length > 200 && (
        <div className="warningmessage">{t("sLogData.warningmessage")}</div>
      )}
    </div>
  );
};

export default EmailForm;
