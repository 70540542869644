import { TLOGS_LOADER_START, TLOGS_LOADER_STOP, SET_TLOGS_LIST } from "./type";

const startTlogsLoader = () => ({
  type: TLOGS_LOADER_START,
});

const stopTlogsLoader = () => ({
  type: TLOGS_LOADER_STOP,
});

const setTlogsList = (payload) => ({
  type: SET_TLOGS_LIST,
  payload,
});

export { startTlogsLoader, stopTlogsLoader, setTlogsList };
