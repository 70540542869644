import {
  SLOGS_LOADER_START,
  SLOGS_LOADER_STOP,
  SET_SLOGS_LIST,
  FILTERED_TABLE_DATA,
} from "./types";

const startSlogsLoader = () => ({
  type: SLOGS_LOADER_START,
});

const stopSlogsLoader = () => ({
  type: SLOGS_LOADER_STOP,
});

const setSlogsList = (data, initialLoad = false) => ({
  type: SET_SLOGS_LIST,
  payload: { data, initialLoad },
});

const setFilteredTableData = (payload) => ({
  type: FILTERED_TABLE_DATA,
  payload,
});

export {
  startSlogsLoader,
  stopSlogsLoader,
  setSlogsList,
  setFilteredTableData,
};
