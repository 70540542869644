import {
  LeftOutlined,
  MenuFoldOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Button, Drawer, InputNumber, List, notification, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import xmlDisable from "../../assets/xml-disable.png";
import xml from "../../assets/xml.png";
import { startPqrDataLoader } from "../../redux/pqrReportData/actions";
import {
  FETCH_PQRDATA_REPORT_LINK_SAGA,
  FETCH_PQRDATA_SAGA,
} from "../../redux/pqrReportData/types";
import PqrTable from "../PqrReportData/PqrTable";

const PqrReportTable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { checkedexcludedTlogsArray } = location.state || {};

  const queryParams = new URLSearchParams(location.search);
  const boundary = queryParams.get("boundary");

  const [editedDataFromTable, setEditedDataFromTable] = useState([]);
  const [isEdited, setIsEdited] = useState(false);
  const [isToleranceChanged, setIsToleranceChanged] = useState(false); // New state for tracking tolerance change

  const handleDataFromTable = (data) => {
    setIsEdited(data.length > 0);
    setEditedDataFromTable(data);
  };

  const [isValidationPassed, setIsValidationPassed] = useState(false);

  const { weld_numbers } = useParams();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const selectedProjectId = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_id
  );
  const selectedProjectName = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_name
  );
  const loader = useSelector((state) => state.pqrdataReducer.isPqrDataLoding);
  const savedBoundary = useSelector((state) => state.pqrdataReducer.boundary);
  const BoundaryValue = boundary ? boundary : savedBoundary;
  const [inputBoundary, setInputBoundary] = useState(BoundaryValue);

  const PqrReportData =
    useSelector((state) => state.pqrdataReducer.pqrdataList) || [];

  const weldNumberArray = weld_numbers ? weld_numbers.split(",").join(",") : [];
  const mapValuesToPayload = (item) => {
    let mappedValues = {
      ...(item.passName && { passName: item.passName }),
      ...(item.preHeatMin && {
        preHeatInterPassTemperatureMin: item.preHeatMin.toString(),
      }),
      ...(item.preHeatMax && {
        preHeatInterPassTemperatureMax: item.preHeatMax.toString(),
      }),
      ...(item.arcEnergyMin && { arcEnergyMin: item.arcEnergyMin.toString() }),
      ...(item.arcEnergyMax && { arcEnergyMax: item.arcEnergyMax.toString() }),
      ...(item.pulseFreqMin && {
        averagePulseFrequencyMin: item.pulseFreqMin.toString(),
      }),
      ...(item.pulseFreqMax && {
        averagePulseFrequencyMax: item.pulseFreqMax.toString(),
      }),
      ...(item.pulseTimeMin && {
        averagePulseTimeMin: item.pulseTimeMin.toString(),
      }),
      ...(item.pulseTimeMax && {
        averagePulseTimeMax: item.pulseTimeMax.toString(),
      }),
      ...(item.peakCurrentMin && {
        averagePeakCurrentMin: item.peakCurrentMin.toString(),
      }),
      ...(item.peakCurrentMax && {
        averagePeakCurrentMax: item.peakCurrentMax.toString(),
      }),
      ...(item.baseCurrentMin && {
        averageBaseCurrentMin: item.baseCurrentMin.toString(),
      }),
      ...(item.baseCurrentMax && {
        averageBaseCurrentMax: item.baseCurrentMax.toString(),
      }),
    };

    return mappedValues;
  };

  const reverseMapValues = (payload) => {
    let originalValues = {
      ...(payload.passName && { passName: payload.passName }),
      ...(payload.preHeatInterPassTemperatureMin && {
        preHeatMin: parseFloat(payload.preHeatInterPassTemperatureMin),
      }),
      ...(payload.preHeatInterPassTemperatureMax && {
        preHeatMax: parseFloat(payload.preHeatInterPassTemperatureMax),
      }),
      ...(payload.arcEnergyMin && {
        arcEnergyMin: parseFloat(payload.arcEnergyMin),
      }),
      ...(payload.arcEnergyMax && {
        arcEnergyMax: parseFloat(payload.arcEnergyMax),
      }),
      ...(payload.averagePulseFrequencyMin && {
        pulseFreqMin: parseFloat(payload.averagePulseFrequencyMin),
      }),
      ...(payload.averagePulseFrequencyMax && {
        pulseFreqMax: parseFloat(payload.averagePulseFrequencyMax),
      }),
      ...(payload.averagePulseTimeMin && {
        pulseTimeMin: parseFloat(payload.averagePulseTimeMin),
      }),
      ...(payload.averagePulseTimeMax && {
        pulseTimeMax: parseFloat(payload.averagePulseTimeMax),
      }),
      ...(payload.averagePeakCurrentMin && {
        peakCurrentMin: parseFloat(payload.averagePeakCurrentMin),
      }),
      ...(payload.averagePeakCurrentMax && {
        peakCurrentMax: parseFloat(payload.averagePeakCurrentMax),
      }),
      ...(payload.averageBaseCurrentMin && {
        baseCurrentMin: parseFloat(payload.averageBaseCurrentMin),
      }),
      ...(payload.averageBaseCurrentMax && {
        baseCurrentMax: parseFloat(payload.averageBaseCurrentMax),
      }),
    };

    return originalValues;
  };

  const PqrreportLink = useSelector(
    (state) => state.pqrdataReducer?.pqrReportLinkData?.recordList
  );

  const Data = PqrreportLink?.[0];

  let excludedTlogsArray = [];

  if (PqrreportLink?.[0]?.excluded_tlogs_key) {
    try {
      // Parse the excluded_tlogs_key JSON string
      const excludedTlogsKeyObject = JSON.parse(
        PqrreportLink[0].excluded_tlogs_key
      );

      // Check if excluded_tlogs_key array has at least one item
      if (excludedTlogsKeyObject?.excluded_tlogs_key?.[0]) {
        // Destructure to get values
        const {
          pass_name,
          torch,
          weld_number,
          excluded_tlogs,
        } = excludedTlogsKeyObject.excluded_tlogs_key[0];

        excludedTlogsArray = [
          {
            pass_name,
            torch,
            weld_number,
            excluded_tlogs,
          },
        ];
      } else {
        console.warn("excluded_tlogs_key array is empty or undefined.");
      }
    } catch (error) {
      console.error("Error parsing excluded_tlogs_key:", error);
    }
  } else {
    console.warn("PqrreportLink[0].excluded_tlogs_key is undefined.");
  }

  const [loadPqrData, setLoadPqrData] = useState(false);
  const [filterPassName, setfilterPassName] = useState([]);

  const checkTlogDataDetails =
    useSelector((state) => state.pqrdataReducer.checkTlogDataDetails) || [];
  console.log("Excluded Tlogs Array:", excludedTlogsArray);
  console.log("checkTlogDataDetails Tlogs Array:", checkTlogDataDetails);
  console.log("checkedexcludedTlogsArray", checkedexcludedTlogsArray);
  const fetchPqrData = (boundaryValue) => {
    const excludedTlogs = [
      checkTlogDataDetails,
      excludedTlogsArray,
      checkedexcludedTlogsArray,
    ].reduce(
      (longestArray, currentArray) =>
        Array.isArray(currentArray) && currentArray.length > longestArray.length
          ? currentArray
          : longestArray,
      []
    );

    let PayloadData = {
      project_id: selectedProjectId,
      project_name: selectedProjectName,
      weld_numbers: weldNumberArray,
      boundary: boundaryValue,
      excluded_tlogs: excludedTlogs,
    };
    console.log("PayloadData---", PayloadData);
    const commonPayload = {
      project_id: selectedProjectId,
      project_name: selectedProjectName,
      weld_numbers: weldNumberArray,
      boundary: boundaryValue,
      shouldExecuteStopLoader: false,
    };

    // dispatch(startPqrDataLoader());
    setLoadPqrData(true);

    dispatch({
      type: FETCH_PQRDATA_REPORT_LINK_SAGA,
      payload: commonPayload,
      cb: (pqrData) => {
        console.log(pqrData, "pqrData");
        const { max_min_data, selected_columns, boundary } =
          pqrData?.recordList?.[0] || {};

        const parsedMaxMinData = JSON.parse(max_min_data || "{}")?.max_min_data;
        const selectedColumns = JSON.parse(
          selected_columns || "{}"
        )?.selected_columns;

        let originalValuesMapped =
          parsedMaxMinData?.map((ite) => {
            return reverseMapValues(ite);
          }) || [];

        if (selectedColumns?.length > 0) {
          let visibleColumns = initialZoneViewColumns?.filter((column) =>
            [...selectedColumns, "Pass Name"].includes(column.backendMapping)
          );
          let hiddenColumns = initialZoneViewColumns?.filter(
            (column) => !selectedColumns.includes(column.backendMapping)
          );

          const BackendMappings = hiddenColumns.map((ite) => {
            // If ite has children, try to access the first child's backendMapping
            if (ite?.children) {
              return Array.isArray(ite.children)
                ? ite.backendMapping
                : ite.backendMapping;
            }
            // Otherwise, return the backendMapping of the current item
            return ite.backendMapping;
          });

          const filterPassName = BackendMappings.filter(
            (title) => title !== "Pass Name" && title !== " "
          );
          setfilterPassName(filterPassName);

          setZoneVisibleColumns(visibleColumns);
          setZoneRemovedColumns(hiddenColumns);
        }

        setEditedDataFromTable([...originalValuesMapped]);

        PayloadData = {
          ...PayloadData,
          ...(parsedMaxMinData && { items: parsedMaxMinData }),
          ...(selectedColumns && { columns_add: selectedColumns }),
          boundary: boundary || PayloadData.boundary,
        };
        dispatch({
          type: FETCH_PQRDATA_SAGA,
          payload: PayloadData,
          cb: () => {
            setLoadPqrData(false);
          },
          error: () => {
            setLoadPqrData(false);
          },
        });
      },
      error: () => {
        setLoadPqrData(false);
      },
    });
  };

  useEffect(() => {
    fetchPqrData(BoundaryValue);
  }, []);

  const initialZoneViewColumns = [
    {
      title: t("pqrReport.passName"),
      dataIndex: "passName",
      backendMapping: "Pass Name",
      key: "name",
      position: 0,
    },
    {
      title: (
        <div>
          {t("pqrReport.preHeat")}
          <br />
          (°C)
        </div>
      ),
      position: 1,
      backendMapping: "Pre-Heat/ Interpass Temperature",
      children: [
        {
          title: "Min",
          dataIndex: "preHeatMin",
          key: "preHeatMin",
        },
        {
          title: "Max",
          dataIndex: "preHeatMax",
          key: "preHeatMax",
        },
      ],
    },
    {
      title: (
        <div>
          {t("pqrReport.avgVoltage")}
          <br /> (V){" "}
        </div>
      ),
      key: "avgVoltage",
      position: 2,
      backendMapping: "Average Voltage",

      children: [
        {
          title: "Min",
          dataIndex: "voltsMin",
          key: "voltsMin",
        },
        {
          title: "Max",
          dataIndex: "voltsMax",
          key: "voltsMax",
        },
      ],
    },
    {
      title: (
        <div>
          {t("pqrReport.avgCurrent")} <br /> (A)
        </div>
      ),
      key: "avgCurrent",
      backendMapping: "Average Current",

      position: 3,
      children: [
        {
          title: "Min",
          dataIndex: "ampsMin",
          key: "ampsMin",
        },
        {
          title: "Max",
          dataIndex: "ampsMax",
          key: "ampsMax",
        },
      ],
    },
    {
      title: (
        <div>
          {t("pqrReport.avgWireFeedSpeed")}
          <br />
          (in/min)
        </div>
      ),
      key: "avgWireFeedSpeed",
      backendMapping: "Average Wire Feed Speed",
      position: 4,
      children: [
        {
          title: "Min",
          dataIndex: "minWireFeedSpeed",
          key: "minWireFeedSpeed",
        },
        {
          title: "Max",
          dataIndex: "maxWireFeedSpeed",
          key: "maxWireFeedSpeed",
        },
      ],
    },
    {
      title: (
        <div>
          {t("pqrReport.avgOscillationWidth")}
          <br /> (in){" "}
        </div>
      ),
      key: "avgOscillationWidth",
      position: 5,
      backendMapping: "Average Oscillation Width",
      children: [
        {
          title: "Min",
          dataIndex: "oscWidthMin",
          key: "oscWidthMin",
        },
        {
          title: "Max",
          dataIndex: "oscWidthMax",
          key: "oscWidthMax",
        },
      ],
    },
    {
      title: (
        <div>
          {t("pqrReport.avgOscillationFrq")}
          <br /> (Hz){" "}
        </div>
      ),
      key: "avgOscillationFrq",
      backendMapping: "Average Oscillation Frequency",
      position: 6,
      children: [
        {
          title: "Min",
          dataIndex: "oscFrqMin",
          key: "oscFrqMin",
        },
        {
          title: "Max",
          dataIndex: "oscFrqMax",
          key: "oscFrqMax",
        },
      ],
    },
    {
      title: (
        <div>
          {t("pqrReport.avgTravelSpeed")}
          <br />
          (in/min)
        </div>
      ),
      backendMapping: "Average Travel Speed",
      key: "avgTravelSpeed",
      position: 7,
      children: [
        {
          title: "Min",
          dataIndex: "travelSpeedMin",
          key: "travelSpeedMin",
        },
        {
          title: "Max",
          dataIndex: "travelSpeedMax",
          key: "travelSpeedMax",
        },
      ],
    },
    {
      title: (
        <div>
          {t("pqrReport.arcEnergy")}
          <br />
          (KJ/mm)
        </div>
      ),
      key: "arcEnergy",
      backendMapping: "Arc Energy",
      position: 8,
      children: [
        {
          title: "Min",
          dataIndex: "arcEnergyMin",
          key: "arcEnergyMin",
        },
        {
          title: "Max",
          dataIndex: "arcEnergyMax",
          key: "arcEnergyMax",
        },
      ],
    },
    {
      title: (
        <div>
          {t("pqrReport.avgPulseFrequency")}
          <br /> (Hz){" "}
        </div>
      ),
      key: "avgPulseFrequency",
      backendMapping: "Average Pulse Frequency",

      position: 9,
      children: [
        {
          title: "Min",
          dataIndex: "pulseFreqMin",
          key: "pulseFreqMin",
        },
        {
          title: "Max",
          dataIndex: "pulseFreqMax",
          key: "pulseFreqMax",
        },
      ],
    },
    {
      title: (
        <div>
          {t("pqrReport.avgPulseTime")}
          <br /> (ms)
        </div>
      ),
      key: "avgPulseTime",
      backendMapping: "Average Pulse Time",

      position: 10,
      children: [
        {
          title: "Min",
          dataIndex: "pulseTimeMin",
          key: "pulseTimeMin",
        },
        {
          title: "Max",
          dataIndex: "pulseTimeMax",
          key: "pulseTimeMax",
        },
      ],
    },
    {
      title: (
        <div>
          {t("pqrReport.avgPeakCurrent")}
          <br />
          (A)
        </div>
      ),
      key: "avgPeakCurrent",
      backendMapping: "Average Peak Current",

      position: 11,
      children: [
        {
          title: "Min",
          dataIndex: "peakCurrentMin",
          key: "peakCurrentMin",
        },
        {
          title: "Max",
          dataIndex: "peakCurrentMax",
          key: "peakCurrentMax",
        },
      ],
    },
    {
      title: (
        <div>
          {t("pqrReport.avgBaseCurrent")}
          <br />
          (A)
        </div>
      ),
      key: "avgBaseCurrent",
      backendMapping: "Average Base Current",
      position: 12,
      children: [
        {
          title: "Min",
          dataIndex: "baseCurrentMin",
          key: "baseCurrentMin",
        },
        {
          title: "Max",
          dataIndex: "baseCurrentMax",
          key: "baseCurrentMax",
        },
      ],
    },
  ];

  const [zoneVisibleColumns, setZoneVisibleColumns] = useState(
    initialZoneViewColumns
  );

  const [zoneRemovedColumns, setZoneRemovedColumns] = useState([]);
  const [modifiedColumns, setModifiedColumns] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const removeColumn = (key, setVisible, setRemoved, visible, removed) => {
    if (visible.length <= 2) {
      notification.warning({
        message:
          "You cannot remove a column, at least one must remain in view.",
      });
      return;
    }

    const removedColumn = visible.find((col) => col.key === key);
    if (removedColumn) {
      const updatedColumns = visible.filter((col) => col.key !== key);
      setVisible(updatedColumns);
      setRemoved([...removed, removedColumn]);
      // Track the column modification
      setModifiedColumns([...modifiedColumns, key]);

      // Update hasChanged state
      setHasChanged(
        checkIfColumnsChanged(updatedColumns, [
          ...zoneRemovedColumns,
          removedColumn,
        ])
      );
    }
  };

  const visibleColumnTitles = zoneVisibleColumns.map((col) => col.title);

  const extractedTitles = visibleColumnTitles.map((col) => {
    if (col?.props?.children) {
      return Array.isArray(col.props.children) ? col.props.children[0] : null;
    }
    return col;
  });

  const columnsAdd = extractedTitles.filter(
    (title) => title !== "Pass Name" && title !== " "
  );

  const checkIfColumnsChanged = (visible) => {
    // If the length or keys of visible/removed columns have changed, mark as changed
    if (visible.length !== initialZoneViewColumns.length) return true;

    return visible.some(
      (col, index) => col.key !== initialZoneViewColumns[index].key
    );
  };

  const removedColumnsData = zoneRemovedColumns.map((ite) => {
    // If ite has children, try to access the first child's backendMapping
    if (ite?.children) {
      return Array.isArray(ite.children)
        ? ite.backendMapping
        : ite.backendMapping;
    }
    // Otherwise, return the backendMapping of the current item
    return ite.backendMapping;
  });

  const filterRemovedColumnsData = removedColumnsData.filter(
    (title) => title !== "Pass Name" && title !== " "
  );

  const addBackColumn = (
    key,
    setVisible,
    setRemoved,
    visible,
    removed,
    filterRemovedColumnsData
  ) => {
    const addedColumn = removed.find((col) => col.key === key);
    if (addedColumn) {
      const updatedRemovedColumns = removed.filter((col) => col.key !== key);
      setRemoved(updatedRemovedColumns);

      // Find the correct position index
      let insertIndex = visible.findIndex(
        (col) => col.position > addedColumn.position
      );
      if (insertIndex === -1) {
        insertIndex = visible.length;
      }

      // Insert the column back at the correct position
      const updatedVisibleColumns = [
        ...visible.slice(0, insertIndex),
        addedColumn,
        ...visible.slice(insertIndex),
      ];
      setVisible(updatedVisibleColumns);

      // Track the column modification
      setModifiedColumns([...modifiedColumns, key]);

      const areEqual = (arr1, arr2) =>
        arr1.length === arr2.length &&
        arr1.every((value) => arr2.includes(value));

      if (areEqual(filterPassName, filterRemovedColumnsData)) {
        setHasChanged(false);
      } else if (filterPassName.includes(addedColumn.backendMapping)) {
        setHasChanged(true);
      } else {
        setHasChanged(false);
      }
    }
  };

  useEffect(() => {
    const areEqual = (arr1, arr2) =>
      arr1.length === arr2.length &&
      arr1.every((value) => arr2.includes(value));

    if (areEqual(filterPassName, filterRemovedColumnsData)) {
      setHasChanged(false);
    } else {
      setHasChanged(true);
    }
  }, [filterRemovedColumnsData, filterPassName]); // Run this effect when either filterRemovedColumnsData or filterPassName changes

  const renderColumnOptions = (
    visibleColumns,
    removedColumns,
    setVisibleColumns,
    setRemovedColumns
  ) => {
    const filteredVisibleColumns = visibleColumns.filter(
      (item) => item.title !== "Pass Name"
    );
    const filteredRemovedColumns = removedColumns.filter(
      (item) => item.title !== "Pass Name"
    );

    return (
      <List
        dataSource={[...filteredVisibleColumns, ...filteredRemovedColumns]}
        renderItem={(item) => (
          <List.Item
            actions={[
              filteredRemovedColumns.includes(item) ? (
                <Button
                  key={`${item.key}-add`}
                  onClick={() =>
                    addBackColumn(
                      item.key,
                      setVisibleColumns,
                      setRemovedColumns,
                      visibleColumns,
                      removedColumns
                    )
                  }
                >
                  {t("common.addBack")}
                </Button>
              ) : (
                <Button
                  key={`${item.key}-remove`}
                  onClick={() => {
                    removeColumn(
                      item.key,
                      setVisibleColumns,
                      setRemovedColumns,
                      visibleColumns,
                      removedColumns
                    );
                  }}
                >
                  {t("common.remove")}
                </Button>
              ),
            ]}
          >
            {item.title}
          </List.Item>
        )}
      />
    );
  };

  const handleBoundaryChange = (value) => {
    setInputBoundary(value);
    setIsToleranceChanged(true);
  };

  const [isGenerateClicked, setIsGenerateClicked] = useState(false);

  const handleReGenerateClick = () => {
    // handleDataFromTable(data);
    if (inputBoundary === null) {
      notification.warning({
        message: "Please enter a valid boundary value to re-generate the data.",
      });
      return;
    }

    setIsGenerateClicked(true);

    let payload = {
      project_id: selectedProjectId,
      project_name: selectedProjectName,
      weld_numbers: weldNumberArray,
      boundary: inputBoundary,
      columns_add: columnsAdd,
      items: editedDataFromTable?.map((ite) => {
        let { key, ...rest } = ite;
        console.log(key);
        return {
          ...mapValuesToPayload(rest),
        };
      }),
      excluded_tlogs:
        checkTlogDataDetails.length > 0
          ? checkTlogDataDetails
          : excludedTlogsArray.length > 0
          ? excludedTlogsArray
          : checkedexcludedTlogsArray,
    };

    console.log("payload222", payload);

    const commonPayload = {
      project_id: selectedProjectId,
      project_name: selectedProjectName,
      weld_numbers: weldNumberArray,
      boundary: BoundaryValue,
      shouldExecuteStopLoader: false,
    };
    dispatch(startPqrDataLoader());
    dispatch({
      type: FETCH_PQRDATA_SAGA,
      payload,
      cb: () => {
        dispatch({
          type: FETCH_PQRDATA_REPORT_LINK_SAGA,
          payload: commonPayload,
          cb: (pqrData) => {
            const { selected_columns } = pqrData?.recordList?.[0] || {};

            const selectedColumns = JSON.parse(
              selected_columns || "{}"
            )?.selected_columns;

            if (selectedColumns?.length > 0) {
              let visibleColumns = initialZoneViewColumns?.filter((column) =>
                [...selectedColumns, "Pass Name"].includes(
                  column.backendMapping
                )
              );
              let hiddenColumns = initialZoneViewColumns?.filter(
                (column) => !selectedColumns.includes(column.backendMapping)
              );

              const BackendMappings = hiddenColumns.map((ite) => {
                // If ite has children, try to access the first child's backendMapping
                if (ite?.children) {
                  return Array.isArray(ite.children)
                    ? ite.backendMapping
                    : ite.backendMapping;
                }
                // Otherwise, return the backendMapping of the current item
                return ite.backendMapping;
              });

              const filterPassName = BackendMappings.filter(
                (title) => title !== "Pass Name" && title !== " "
              );
              setfilterPassName(filterPassName);

              setZoneVisibleColumns(visibleColumns);
              setZoneRemovedColumns(hiddenColumns);
            }
          },
        });
      },
    });
  };

  const fetchPQRReportLinkData = () => {
    const payload = {
      project_id: selectedProjectId,
      project_name: selectedProjectName,
      weld_numbers: weldNumberArray,
      boundary: inputBoundary,
    };

    dispatch({
      type: FETCH_PQRDATA_REPORT_LINK_SAGA,
      payload,
    });
    setIsGenerateClicked(false);
  };

  const reportGeneratedData = useSelector(
    (state) => state.pqrdataReducer?.reportGeneratedInfoList?.recordList
  );

  const maxMinData = Data?.max_min_data
    ? JSON.parse(Data.max_min_data).max_min_data
    : [];

  const matchingReport = reportGeneratedData?.find((report) => {
    const reportWeldNumbers = report.weld_numbers;
    return reportWeldNumbers === weldNumberArray;
  });
  const weldNumbersMatch = Data?.weld_numbers == weldNumberArray;

  const handleDownload = () => {
    if (matchingReport?.pqr_report_url) {
      window.open(Data?.pqr_report_url, "_blank");
    } else if (weldNumbersMatch && Data?.pqr_report_url !== null) {
      window.open(Data?.pqr_report_url, "_blank");
    } else {
      console.log("No matching report or URL not found");
    }
  };

  return (
    <>
      <div className="slogweld-title">
        <LeftOutlined className="leftoutlined" />
        <Link to="/data360-home/pqr-report">
          <h2 className="slogweld-header-active">PQR Report</h2>
        </Link>

        <LeftOutlined className="leftoutlined" />
        <h2 className="slogweld-header-weldnuber">
          {t("slogColumntitle.weld")} {weldNumberArray}
        </h2>
        {!loader && (
          <>
            <div className="boundary-input">
              <label className="tolerance-lable">Tolerance</label>
              <InputNumber
                defaultValue={inputBoundary}
                formatter={(value) => `${value}%`}
                onChange={handleBoundaryChange}
                className="re-generate-input"
              />
              <Button
                type="primary"
                className="re-generate-btn"
                onClick={(data) => handleReGenerateClick(data)}
                disabled={
                  !hasChanged &&
                  !isEdited &&
                  !isValidationPassed &&
                  !isToleranceChanged
                }
              >
                Generate
              </Button>
              <Tooltip
                placement="topLeft"
                title={
                  !(
                    matchingReport?.pqr_report_url ||
                    (weldNumbersMatch && Data?.pqr_report_url !== null)
                  )
                    ? "Please wait until the report generates."
                    : null
                }
              >
                {isGenerateClicked ||
                !(
                  matchingReport?.pqr_report_url ||
                  (weldNumbersMatch && Data?.pqr_report_url !== null)
                ) ? (
                  <img
                    src={xmlDisable}
                    alt="Download PQR Report"
                    className="re-generate-btn-save-pd-disable"
                  />
                ) : (
                  <img
                    src={xml}
                    alt="Download PQR Report"
                    onClick={handleDownload}
                    className="re-generate-btn-save-pd"
                  />
                )}
              </Tooltip>

              {(isGenerateClicked ||
                !(
                  matchingReport?.pqr_report_url ||
                  (weldNumbersMatch && Data?.pqr_report_url !== null)
                )) && (
                <Tooltip placement="topLeft" title="Reload">
                  <Button
                    className="refresh-download"
                    onClick={() => fetchPQRReportLinkData()}
                  >
                    <ReloadOutlined />
                  </Button>
                </Tooltip>
              )}
            </div>
            <Button
              key="cancel"
              type="secondary"
              className="column-option"
              onClick={showDrawer}
            >
              <MenuFoldOutlined />
              {t("common.columnOptions")}
            </Button>
          </>
        )}
      </div>
      <PqrTable
        visibleColumns={zoneVisibleColumns}
        weld_number={weldNumberArray}
        loadPqrData={loadPqrData}
        PqrReportData={PqrReportData}
        handleDataFromTable={handleDataFromTable}
        setIsEdited={setIsEdited}
        setIsValidationPassed={setIsValidationPassed}
        editedDataFromTable={editedDataFromTable}
        maxMinData={maxMinData}
        boundary={boundary}
        setEditedDataFromTable={setEditedDataFromTable}
      />
      <Drawer
        title="Column Options"
        placement="right"
        onClose={closeDrawer}
        open={drawerVisible}
        className="drawer"
        width={500}
      >
        {renderColumnOptions(
          zoneVisibleColumns,
          zoneRemovedColumns,
          setZoneVisibleColumns,
          setZoneRemovedColumns
        )}
      </Drawer>
    </>
  );
};

export default PqrReportTable;
