const FETCH_SLOGS_REPORT_SAGA = "@@slogs/fetchSlogReportSaga";
const FETCH_PQR_REPORT_SAGA = "@@slogs/fetchPqrReportSaga";
const SET_SLOGS_REPORT_LIST = "@@slogs/setSlogReportList";
const SET_ZONE_REPORT_LIST = "@@slogs/setZoneReportList";
const SET_PASS_REPORT_LIST = "@@slogs/setPassReportList";
const SLOGS_LOADER_REPORT_START = "@@slogs/startLoader";
const SLOGS_LOADER_REPORT_STOP = "@@slogs/stopLoader";
const SEND_ZONE_VIEW_EMAIL = "@@slogs/sendZoneViewEmail";
const SEND_PASS_VIEW_EMAIL = "@@slogs/sendPassViewEmail";

export {
  FETCH_SLOGS_REPORT_SAGA,
  FETCH_PQR_REPORT_SAGA,
  SET_SLOGS_REPORT_LIST,
  SLOGS_LOADER_REPORT_START,
  SLOGS_LOADER_REPORT_STOP,
  SET_ZONE_REPORT_LIST,
  SET_PASS_REPORT_LIST,
  SEND_ZONE_VIEW_EMAIL,
  SEND_PASS_VIEW_EMAIL,
};
