const FETCH_STATUS_CALCULATION_SAGA = "@@status/fetchStatusSaga";
const SET_STATUS_CALCULATION_LIST = "@@status/setStatusList";
const STATUS_CALCULATION_LOADER_START = "@@status/startLoader";
const STATUS_CALCULATION_LOADER_STOP = "@@status/stopLoader";

export {
  FETCH_STATUS_CALCULATION_SAGA,
  SET_STATUS_CALCULATION_LIST,
  STATUS_CALCULATION_LOADER_START,
  STATUS_CALCULATION_LOADER_STOP,
};
