import {
  SWITCH_THEME,
  TOGGLE_PROJECT_DETAILS,
  TOGGLE_PROJECT_LIST,
  TOGGLE_SIDEBAR,
  TOGGLE_THEME,
} from "./types";

const initialState = {
  switchTheme: false,
  toggleSideBar: true,
  toggleProjectList: true,
  toggleProjectDetails: false,
};

const switchThemeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SWITCH_THEME:
      return {
        ...state,
        switchTheme: action.payload,
      };

    case TOGGLE_THEME:
      return {
        ...state,
        switchTheme: !state.switchTheme,
      };
    case TOGGLE_SIDEBAR:
      return { ...state, toggleSideBar: action.payload };
    case TOGGLE_PROJECT_LIST:
      return { ...state, toggleProjectList: action.payload };
    case TOGGLE_PROJECT_DETAILS:
      return { ...state, toggleProjectDetails: action.payload };
    default:
      return state;
  }
};

export default switchThemeReducer;
