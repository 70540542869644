import {
  DownloadOutlined,
  EyeOutlined,
  FunnelPlotOutlined,
  SendOutlined,
} from "@ant-design/icons";
import {
  Tabs,
  Radio,
  Button,
  Drawer,
  Form,
  DatePicker,
  Select,
  Modal,
  notification,
  Tooltip,
  Tag,
} from "antd";
import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import pdfdisable from "../assets/pdf-disable.png";
import pdf from "../assets/pdf.png";
import EmailForm from "../components/Common/EmailForm";
import CommonLoader from "../components/Loaders/loader";
import MapBox from "../components/MapBox";
import LogData from "../components/subcomponents/LogData";

import StatusConfiguration from "../components/subcomponents/StatusConfiguration";
import { radioList } from "../helpers";
import {
  FETCH_PQRDATA_REPORT_SAGA,
  SET_REPORT_GENERATED_INFO_DATA_LIST,
} from "../redux/pqrReportData/types";
import {
  FETCH_SLOGS_REPORT_SAGA,
  SEND_PASS_VIEW_EMAIL,
  SEND_ZONE_VIEW_EMAIL,
} from "../redux/reportGenerator/types";
import { setFilteredTableData } from "../redux/sLogdata/actions";
import { FETCH_SLOGS_SAGA } from "../redux/sLogdata/types";
import { startStatusLoader } from "../redux/statusCalculation/actions";
import { FETCH_STATUS_CALCULATION_SAGA } from "../redux/statusCalculation/types";

const { Option } = Select;

let tabKeys = {
  logData: "logData",
  tableView: "tableView",
  mapView: "mapView",
  StatusConfiguration: "StatusConfiguration",
  PQRReport: "PQRReport",
};

const TabList = () => {
  const [selectedView, setSelectedView] = useState(tabKeys.tableView);
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [emails, setEmails] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filters, setFilters] = useState({
    sl_no: "",
    weld_number: "",
    status: "",
    weld_time: "",
  });
  const [selectedWeldNumbers, setSelectedWeldNumbers] = useState([]);
  const location = useLocation();

  const defaultActiveKey = useMemo(() => {
    if (location.pathname === "/data360-home/pqr-report") {
      return tabKeys.PQRReport;
    }
    return tabKeys.tableView;
  }, [location.pathname]);

  const selectedProjectId = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_id
  );
  const selectedProjectName = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_name
  );
  const slogData = useSelector((state) => state.slogReducer?.slogsList);

  const filteredTableData = useSelector((state) => state.slogReducer.slogsList);

  const reportGeneratedData = useSelector(
    (state) => state.pqrdataReducer?.reportGeneratedInfoList?.recordList
  );

  const Loader = useSelector((state) => state.pqrdataReducer?.isPqrDataLoding);

  const StatusData = useSelector((state) => state.statusReducer);
  const tableLimitData = StatusData?.statusList?.allDataSet?.recordList;
  const hasJobNumbers = tableLimitData?.some((item) => item.job_number);

  useEffect(() => {
    if (selectedProjectId && selectedProjectName) {
      const params = {
        skip: 0,
        limit: 100,
        project_id: selectedProjectId,
        project_name: selectedProjectName,
      };
      dispatch(startStatusLoader());
      dispatch({ type: FETCH_STATUS_CALCULATION_SAGA, payload: params });
    }
  }, [dispatch, selectedProjectId, selectedProjectName]);

  const weldNumbers = useMemo(
    () => [...new Set((slogData || []).map((item) => item.weldNumber))],
    [slogData]
  );

  const handleWeldNumberChange = (values) => {
    setSelectedWeldNumbers(values);
    if (values.length === 0) {
      setOpendiv(false);
    }
  };

  const handleClose = (removedNumber) => {
    setSelectedWeldNumbers((prevNumbers) =>
      prevNumbers.filter((number) => number !== removedNumber)
    );
  };

  const handleStartDateChange = (date) => {
    setStartDate(date ? date.valueOf() : null);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date ? date.valueOf() : null);
  };

  const handleViewChange = (activeKey) => {
    setSelectedView(activeKey);
  };

  const toggleTableMap = () => {
    setIsMapVisible((isMapVisible) => !isMapVisible);
  };

  const handleReset = () => {
    form.resetFields();
    setSelectedWeldNumbers([]);
    setStartDate(null);
    setEndDate(null);
    setFilters({
      sl_no: "",
      weld_number: "",
      status: "",
      weld_time: "",
    });
  };

  const filteredDataSource = useMemo(() => {
    let data = slogData || [];

    if (selectedWeldNumbers.length > 0) {
      data = data.filter((item) =>
        selectedWeldNumbers.includes(item.weldNumber)
      );
    }

    if (startDate && endDate) {
      data = data.filter((item) => {
        const itemTime = item.slogs[0]?.time * 1000000;
        return itemTime >= startDate && itemTime <= endDate;
      });
    }

    return data;
  }, [slogData, selectedWeldNumbers, startDate, endDate]);

  const dataSource = useMemo(() => {
    return filteredDataSource.map((item, i) => {
      const totalWeldTime = item.slogs
        .reduce((acc, curr) => acc + Number(curr.weld_time) || 0, 0)
        .toFixed(2);

      return {
        key: i,
        sl_no: i + 1,
        weld_number: item.weldNumber ?? "-",
        status: item.slogStatus[0]?.status ?? "-",
        weld_time: totalWeldTime,
      };
    });
  }, [filteredDataSource]);

  const filteredData = useMemo(() => {
    return dataSource.filter((item) =>
      Object.keys(filters).every((key) => {
        if (!filters[key]) return true;
        const value = (item[key] || "").toString();
        if (Array.isArray(filters[key])) {
          if (filters[key].length === 0) return true;
          return filters[key].some((val) =>
            value.toLowerCase().includes(val.toLowerCase())
          );
        } else {
          return value.toLowerCase().includes(filters[key].toLowerCase());
        }
      })
    );
  }, [dataSource, filters]);

  useEffect(() => {
    dispatch(setFilteredTableData(filteredData));
  }, [dispatch, filteredData]);

  const fetchPQRReportData = () => {
    const payload = {
      project_id: selectedProjectId,
      project_name: selectedProjectName,
    };

    dispatch({
      type: SET_REPORT_GENERATED_INFO_DATA_LIST,
      payload,
    });
  };

  useEffect(() => {
    if (selectedProjectId) {
      fetchPQRReportData();
    }
    dispatch({
      type: FETCH_SLOGS_SAGA,
      payload: {
        project_id: selectedProjectId,
        project_name: selectedProjectName,
      },
    });
  }, [selectedProjectId, selectedProjectName, selectedView, dispatch]);

  const handlePQRGenerayedReportClick = (weldNumber, boundary) => {
    history.push(`/pqr-table-data/${weldNumber}?boundary=${boundary}`);
  };

  const openSelectedDiv = () => {
    history.push(`/pqr-table-data/${selectedWeldNumbers}`);
  };

  const [isModalVisibletwo, setIsModalVisibletwo] = useState(false);
  const [emailFormtwo] = Form.useForm();
  const [weldNumberArray, setWeldNumberArray] = useState([]);
  const [inputBoundary, setInputBoundary] = useState(null);
  const [openShareEmailReportData, setOpenShareEmailReportData] = useState({});

  const openDownloadModaltwo = (weldNumbers, boundary, reportData) => {
    setWeldNumberArray(weldNumbers);
    setInputBoundary(boundary);
    setIsModalVisibletwo(true);
    setOpenShareEmailReportData(reportData);
  };

  const closeDownloadModaltwo = () => {
    setIsLoading(false);
    setIsModalVisibletwo(false);
    setEmails([]);
    emailFormtwo.resetFields();
  };

  const PqrreportLink = useSelector(
    (state) => state.pqrdataReducer?.pqrReportLinkData?.recordList
  );

  let excludedTlogsArray = [];

  if (PqrreportLink?.[0]?.excluded_tlogs_key) {
    try {
      // Parse the excluded_tlogs_key JSON string
      const excludedTlogsKeyObject = JSON.parse(
        PqrreportLink[0].excluded_tlogs_key
      );

      // Check if excluded_tlogs_key array has at least one item
      if (excludedTlogsKeyObject?.excluded_tlogs_key?.[0]) {
        // Destructure to get values
        const {
          pass_name,
          torch,
          weld_number,
          excluded_tlogs,
        } = excludedTlogsKeyObject.excluded_tlogs_key[0];

        excludedTlogsArray = [
          {
            pass_name,
            torch,
            weld_number,
            excluded_tlogs,
          },
        ];
      } else {
        console.warn("excluded_tlogs_key array is empty or undefined.");
      }
    } catch (error) {
      console.error("Error parsing excluded_tlogs_key:", error);
    }
  } else {
    console.warn("PqrreportLink[0].excluded_tlogs_key is undefined.");
  }

  console.log("excludedTlogsArray", excludedTlogsArray);
  const handleDownloadtwo = () => {
    if (!selectedProjectId) {
      notification.error({
        message: "Project ID is missing",
      });
      return;
    }

    setIsLoading(true);

    const { max_min_data, selected_columns } = openShareEmailReportData || {};

    const parsedMaxMinData = JSON.parse(max_min_data || "{}")?.max_min_data;
    const selectedColumns = JSON.parse(
      selected_columns || "{}"
    )?.selected_columns;

    const payload = {
      project_id: selectedProjectId,
      project_name: selectedProjectName,
      weld_numbers: weldNumberArray,
      boundary: inputBoundary,
      email_address: emails.join(","),
      ...(parsedMaxMinData && { items: parsedMaxMinData }),
      ...(selectedColumns && { columns_add: selectedColumns }),
      excluded_tlogs: excludedTlogsArray,
    };

    console.log("payload", payload);
    console.log("excludedTlogsArray", excludedTlogsArray);

    dispatch({
      type: FETCH_PQRDATA_REPORT_SAGA,
      payload,
      cb: () => {
        closeDownloadModaltwo();
      },
      error: closeDownloadModaltwo,
    });
  };

  const renderPQRReportContent = () => (
    <>
      {Loader ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : (
        <>
          {tableLimitData && tableLimitData.length > 0 && hasJobNumbers ? (
            <>
              <div className="pqr-report-div">
                <Select
                  mode="multiple"
                  placeholder="Select Weld Numbers"
                  value={selectedWeldNumbers}
                  onChange={handleWeldNumberChange}
                  allowClear
                  className="pqr-report"
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {weldNumbers.map((number) => (
                    <Option key={number} value={number}>
                      {number}
                    </Option>
                  ))}
                </Select>
                {selectedWeldNumbers.length > 0 && (
                  <Button
                    type="primary"
                    className="pqrButton"
                    onClick={() => openSelectedDiv()}
                  >
                    PQR Report
                  </Button>
                )}
                <div className="selected-weld-numbers">
                  {selectedWeldNumbers.map((number) => (
                    <Tag
                      key={number}
                      closable
                      onClose={() => handleClose(number)}
                      className="options-show"
                    >
                      {number}
                    </Tag>
                  ))}
                </div>
              </div>

              {reportGeneratedData && reportGeneratedData.length > 0 ? (
                <div className="header">
                  <div>Sr.no</div>
                  <div>Weld Number</div>
                  <div>PassView Report</div>
                  <div>Tolerance</div>
                  <div>PQR Report</div>
                </div>
              ) : null}
              {reportGeneratedData && reportGeneratedData.length > 0
                ? reportGeneratedData.map((report, index) => (
                    <>
                      <div className="pqr-gen-div" key={report.id}>
                        <div className="row">
                          <div className="sr_no">{index + 1}</div>
                          <div className="weld-numbers">
                            {report.weld_numbers
                              .split(",")
                              .map((weldNumber, index) => {
                                return (
                                  <span className="weld-number" key={index}>
                                    <span className="weld-bg">
                                      {weldNumber.trim()}
                                    </span>{" "}
                                  </span>
                                );
                              })}
                          </div>
                          <div className="document-icons">
                            {report.weld_numbers
                              .split(",")
                              .map((weldNumber, index) => {
                                const weldReport = report.weldReports.find(
                                  (wr) => wr.weld_number === weldNumber.trim()
                                );
                                return weldReport ? (
                                  <span className="document-icon" key={index}>
                                    <Tooltip
                                      title="Download PassView Report"
                                      placement="top"
                                    >
                                      <a
                                        href={weldReport.report_url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          src={pdf}
                                          alt="Pdf"
                                          className="Passvie-pdf"
                                        />
                                      </a>
                                    </Tooltip>
                                  </span>
                                ) : (
                                  <span className="document-icon">
                                    <Tooltip
                                      title="PassView Report has not been generated yet."
                                      placement="top"
                                    >
                                      <img
                                        src={pdfdisable}
                                        alt="Pdf-disabled"
                                        className="Pdf-disabled"
                                      />
                                    </Tooltip>
                                  </span>
                                );
                              })}
                          </div>
                          <div className="percentage">
                            <span className="boundary-percetage">
                              {report.boundary}%
                            </span>
                          </div>
                          <div className="action-icons">
                            <span
                              className="viewanddownloadeye"
                              onClick={() =>
                                handlePQRGenerayedReportClick(
                                  report.weld_numbers
                                    .split(",")
                                    .map((num) => num.trim()),
                                  report.boundary
                                )
                              }
                            >
                              <Tooltip title="View PQR Report" placement="top">
                                <EyeOutlined />
                              </Tooltip>
                            </span>
                            {report.pqr_report_url ? (
                              <Tooltip
                                title="Download PQR Report"
                                placement="top"
                              >
                                <span
                                  className="viewanddownload"
                                  onClick={() =>
                                    window.open(report.pqr_report_url, "_blank")
                                  }
                                >
                                  <DownloadOutlined />
                                </span>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="PQR Report has not been generated yet"
                                placement="top"
                              >
                                <span className="viewanddownload-disable">
                                  <DownloadOutlined />
                                </span>
                              </Tooltip>
                            )}
                            {report.pqr_report_url ? (
                              <Tooltip title="Share PQR Report" placement="top">
                                <span
                                  className="share-pqr-report"
                                  onClick={() =>
                                    openDownloadModaltwo(
                                      report.weld_numbers
                                        .split(",")
                                        .map((num) => num.trim())
                                        .join(","),
                                      report.boundary,
                                      report
                                    )
                                  }
                                >
                                  <SendOutlined />
                                </span>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title="PQR Report has not been generated yet"
                                placement="top"
                              >
                                <span className="share-pqr-report-disable">
                                  <SendOutlined />
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  ))
                : null}
            </>
          ) : (
            <div className="slog-NoData">
              <p>
                PQR Report cannot be generated as Weld parameters are missing
                for this project.
              </p>
            </div>
          )}
        </>
      )}
    </>
  );

  const tabItems = [
    {
      key: tabKeys.tableView,
      label: "Log Data",
      children: isMapVisible ? <MapBox /> : <LogData />,
    },
    {
      key: tabKeys.StatusConfiguration,
      label: "Status Configuration",
      children: <StatusConfiguration />,
    },
    {
      key: tabKeys.PQRReport,
      label: "PQR Report",
      children: renderPQRReportContent(),
    },
  ];

  const selectedProject = useSelector(
    (state) => state?.projectReducer?.selectedProject
  );

  const formatWeldingSystem = (welding_system) => {
    const possibleValues = { P600Z: false, P625: false };

    if (typeof welding_system === "string") {
      try {
        const parsed = JSON.parse(welding_system);
        if (typeof parsed === "object" && parsed !== null) {
          return parsed;
        }
      } catch (e) {
        if (welding_system === "P600Z" || welding_system === "P625") {
          return { ...possibleValues, [welding_system]: true };
        } else {
          console.error("Invalid string value for welding_system.");
          return "-";
        }
      }
    } else if (typeof welding_system === "object" && welding_system !== null) {
      return welding_system;
    } else {
      console.error(
        "Invalid input: welding_system must be a string or object."
      );
      return "-";
    }
  };

  const isMapValid = () => {
    const weldingSystem = formatWeldingSystem(selectedProject.welding_system);
    if (
      weldingSystem["P600Z"] === true &&
      selectedProject.weldAssignmentType === "GPS"
    ) {
      return true;
    }
    return false;
  };

  const openFilterDrawer = () => {
    setShowFilters(true);
  };
  const closeFilterDrawer = () => {
    setShowFilters(false);
  };

  const openDownloadModal = () => {
    setIsModalVisible(true);
  };

  const closeDownloadModal = () => {
    setIsLoading(false);
    setIsModalVisible(false);
    setSelectedRadio();
    setEmails([]);
    emailForm.resetFields();
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
  };

  const [emailForm] = Form.useForm();

  const handleDownload = () => {
    if (weldNumbers.length > 200) {
      notification.warning({
        message: "Email cannot be generated for more than 200 weld numbers",
      });
      return;
    }

    if (!selectedProjectId) {
      notification.error({
        message: "Project ID is missing",
      });
      return;
    }

    setIsLoading(true);

    const payload = {
      project_id: selectedProjectId,
      project_name: selectedProjectName,
      ...(startDate && { start_date: startDate }),
      ...(endDate && { end_date: endDate }),
      ...(selectedWeldNumbers.length > 0 && {
        weld_numbers: selectedWeldNumbers,
      }),

      email_address: emails.join(","),
    };

    switch (selectedRadio) {
      case radioList.radioButton.radioButtonFirst:
        dispatch({
          type: FETCH_SLOGS_REPORT_SAGA,
          payload,
          cb: closeDownloadModal,
          error: closeDownloadModal,
        });
        break;
      case radioList.radioButton.radioButtonThird:
        dispatch({
          type: SEND_PASS_VIEW_EMAIL,
          payload,
          cb: closeDownloadModal,
          error: closeDownloadModal,
        });
        break;
      case radioList.radioButton.radioButtonSecond:
        dispatch({
          type: SEND_ZONE_VIEW_EMAIL,
          payload,
          cb: closeDownloadModal,
          error: closeDownloadModal,
        });
    }
  };

  const renderTabExtraContent = () => {
    return (
      <>
        {isMapValid() && selectedView === tabKeys.tableView && (
          <Radio.Group
            onChange={toggleTableMap}
            className="custom-radio-group"
            value={isMapVisible}
          >
            <>
              <Radio.Button
                className={
                  isMapVisible
                    ? "ant-radio-button-wrapper"
                    : "ant-radio-button-wrapper-checked"
                }
              >
                {t("common.table")}
              </Radio.Button>
              <Radio.Button
                className={
                  isMapVisible
                    ? "ant-radio-button-wrapper-checked"
                    : "ant-radio-button-wrapper"
                }
              >
                {t("common.map")}
              </Radio.Button>
            </>
          </Radio.Group>
        )}
        {filteredTableData.length > 0 &&
          selectedView !== "StatusConfiguration" &&
          selectedView !== "PQRReport" && (
            <>
              <Button
                type="primary"
                className="reportPdfdownload"
                onClick={openDownloadModal}
              >
                {t("sLogData.generateReport")}
              </Button>
              <Button
                type="primary"
                className="ant-btn-circle"
                onClick={openFilterDrawer}
              >
                <FunnelPlotOutlined style={{ fontSize: "20px" }} />
              </Button>
            </>
          )}
        <Drawer
          title="Filters"
          placement="right"
          onClose={closeFilterDrawer}
          open={showFilters}
          className="drawer"
          width={258}
        >
          <Form layout="vertical" className="filter_form" form={form}>
            <h6 className="filter_by">{t("sLogData.filterBy")}</h6>
            <Form.Item name="startDate">
              <DatePicker
                showTime={{ format: "HH:mm" }}
                onChange={handleStartDateChange}
                placeholder="Start Date & Time"
                value={startDate ? moment(startDate) : null}
                format="YYYY-MM-DD HH:mm"
              />
            </Form.Item>
            <Form.Item name="endDate">
              <DatePicker
                showTime={{ format: "HH:mm" }}
                onChange={handleEndDateChange}
                placeholder="End Date & Time"
                value={endDate ? moment(endDate) : null}
                format="YYYY-MM-DD HH:mm"
              />
            </Form.Item>
            <Form.Item name="weldNumber" className="weldNumber">
              <Select
                mode="multiple"
                placeholder="Weld Number"
                allowClear
                value={selectedWeldNumbers}
                onChange={handleWeldNumberChange}
              >
                {weldNumbers.map((number) => (
                  <Option key={number} value={number}>
                    {number}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button
                key="reset"
                onClick={handleReset}
                type="primary"
                className="resetbtn"
              >
                {t("common.reset")}
              </Button>
            </Form.Item>
          </Form>
        </Drawer>
      </>
    );
  };

  return (
    <>
      <Tabs
        defaultActiveKey={defaultActiveKey}
        onChange={handleViewChange}
        items={tabItems}
        className="custom-tab"
        destroyInactiveTabPane={true}
        tabBarExtraContent={renderTabExtraContent()}
      />
      <Modal
        title={t("sLogData.downloadReport")}
        open={isModalVisible}
        onCancel={closeDownloadModal}
        className="report-modal-style"
        width={600}
        footer={[
          <Button key="cancel" onClick={closeDownloadModal}>
            {t("common.cancel")}
          </Button>,
          <Button
            key="download"
            type="primary"
            onClick={handleDownload}
            disabled={
              ([
                radioList.radioButton.radioButtonThird,
                radioList.radioButton.radioButtonSecond,
                radioList.radioButton.radioButtonFirst,
              ].includes(selectedRadio) &&
                emails.length === 0) ||
              selectedRadio === undefined
            }
            className={selectedRadio === undefined ? "disabledbutton" : ""}
          >
            {selectedRadio === radioList.radioButton.radioButtonThird ||
            selectedRadio === radioList.radioButton.radioButtonSecond ||
            selectedRadio === radioList.radioButton.radioButtonFirst
              ? isLoading
                ? t("Sending")
                : t("Send")
              : isLoading
              ? t("common.downloading")
              : t("common.download")}
          </Button>,
        ]}
      >
        <Radio.Group
          onChange={handleRadioChange}
          value={selectedRadio}
          className="report-download"
          disabled={isLoading}
        >
          <Radio value={radioList.radioButton.radioButtonFirst}>
            {t("sLogData.tlogReport")}
          </Radio>
          <Radio value={radioList.radioButton.radioButtonThird}>
            {t("sLogData.passemail")}
          </Radio>
          <Radio value={radioList.radioButton.radioButtonSecond}>
            {t("sLogData.zoneemail")}
          </Radio>
        </Radio.Group>
        {(selectedRadio === radioList.radioButton.radioButtonThird ||
          selectedRadio === radioList.radioButton.radioButtonSecond ||
          selectedRadio === radioList.radioButton.radioButtonFirst) && (
          <EmailForm
            weldNumbers={weldNumbers}
            emails={emails}
            setEmails={setEmails}
          />
        )}
      </Modal>
      <Modal
        title={t("sLogData.shareReport")}
        open={isModalVisibletwo}
        onCancel={closeDownloadModaltwo}
        className="report-modal-style"
        width={600}
        footer={[
          <Button key="cancel" onClick={closeDownloadModaltwo}>
            {t("common.cancel")}
          </Button>,
          <Button
            key="download"
            type="primary"
            onClick={handleDownloadtwo}
            disabled={emails.length === 0}
          >
            {isLoading ? "Sending" : "Send"}
          </Button>,
        ]}
      >
        <EmailForm emails={emails} setEmails={setEmails} />
      </Modal>
    </>
  );
};

export default TabList;
