const FETCH_PQRDATA_SAGA = "@@pqrdata/fetchPqrDataSaga";
const POST_EXCLUDED_SAGA = "@@pqrdata/exlcudetlogSaga"
const FETCH_PQRDATA_REPORT_SAGA = "@@pqrdata/fetchPqrDataReportSaga";
const FETCH_PQRDATA_REPORT_LINK_SAGA = "@@pqrdata/fetchPqrDataReportLinkSaga";
const SET_REPORT_GENERATED_INFO_DATA_LIST =
  "@@pqrdata/setReportGeneratedInfoSaga";
const PQRDATA_LOADER_START = "@@pqrdata/startLoader";
const PQRDATA_LOADER_STOP = "@@pqrdata/stopLoader";
const CHECKED_TLOG_DATA = "@@pqrdata/checkTlogData";

export {
  FETCH_PQRDATA_SAGA,
  PQRDATA_LOADER_START,
  PQRDATA_LOADER_STOP,
  FETCH_PQRDATA_REPORT_SAGA,
  FETCH_PQRDATA_REPORT_LINK_SAGA,
  SET_REPORT_GENERATED_INFO_DATA_LIST,
  CHECKED_TLOG_DATA,
  POST_EXCLUDED_SAGA
};
