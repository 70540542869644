import { combineReducers } from "redux";

import switchThemeReducer from "./application/reducer";
import pqrdataReducer from "./pqrReportData/reducer";
import projectReducer from "./project/reducer";
import auth from "./reducer/authReducer";
import clientRequest from "./reducer/clientReducer";
import slogsReportReducer from "./reportGenerator/reducer";
import rolesReduceer from "./roles/reducer";
import slogReducer from "./sLogdata/reducer";
import statusReducer from "./statusCalculation/reducer";
import tlogsReducer from "./tLogData/reducer";
import { SIGNOUT } from "./types";
import zoneviewReducer from "./zoneView/reducer";

const appReducer = combineReducers({
  auth,
  clientRequest,
  rolesReduceer,
  projectReducer,
  switchThemeReducer,
  slogReducer,
  zoneviewReducer,
  tlogsReducer,
  slogsReportReducer,
  statusReducer,
  pqrdataReducer,
});

const rootReducer = (state, action) => {
  const newState = action.type === SIGNOUT ? undefined : state;
  return appReducer(newState, action);
};

export default rootReducer;
