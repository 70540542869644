import {
  SLOGS_LOADER_REPORT_START,
  SLOGS_LOADER_REPORT_STOP,
  SET_SLOGS_REPORT_LIST,
  SET_ZONE_REPORT_LIST,
  SET_PASS_REPORT_LIST,
} from "./types";

const startSlogReportLoader = () => ({
  type: SLOGS_LOADER_REPORT_START,
});

const stopSlogReportLoader = () => ({
  type: SLOGS_LOADER_REPORT_STOP,
});

const setSlogReportList = (payload) => ({
  type: SET_SLOGS_REPORT_LIST,
  payload,
});
const setZoneReportList = (payload) => ({
  type: SET_ZONE_REPORT_LIST,
  payload,
});
const setPassReportList = (payload) => ({
  type: SET_PASS_REPORT_LIST,
  payload,
});

export {
  startSlogReportLoader,
  stopSlogReportLoader,
  setSlogReportList,
  setZoneReportList,
  setPassReportList,
};
