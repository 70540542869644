let scopes = {
  application: {
    read: "module/application:read",
    edit: "module/application:edit",
  },
  project: {
    read: "module/project:read",
    create: "module/project:create",
    edit: "module/project:edit",
    delete: "module/project:delete",
  },
  setting: {
    read: "module/setting:read",
    write: "module/setting:write",
  },
};

let role = {
  admin: "data360-admin",
  projectManager: "data360-project-manager",
  technician: "data360-technician",
  customer: "data360-customer",
};

let data360Roles = [
  role.admin,
  role.projectManager,
  role.technician,
  role.customer,
];

function customSort(a, b) {
  const order = [
    role.admin,
    role.projectManager,
    role.technician,
    role.customer,
  ];

  return order.indexOf(a) - order.indexOf(b);
}

let RBAC = [
  {
    name: role.admin,
    scopes: [
      scopes.application.read,
      scopes.application.edit,
      scopes.project.create,
      scopes.project.read,
      scopes.project.edit,
      scopes.project.delete,
      scopes.setting.read,
      scopes.setting.write,
    ],
  },
  {
    name: role.projectManager,
    scopes: [scopes.application.read, scopes.project.read, scopes.project.edit],
  },
  {
    name: role.technician,
    scopes: [scopes.application.read, scopes.project.read],
  },
  {
    name: role.customer,
    scopes: [scopes.application.read, scopes.project.read],
  },
];

export { RBAC, scopes, role, data360Roles, customSort };
