/* eslint-disable react/jsx-props-no-spreading */
import { Hub } from "@aws-amplify/core";
import { Layout } from "antd";
import get from "lodash/get";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import "./style.scss";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import routes from "./routes";
import Sidebar from "../layout/sidebar";
import ForgotPassword from "../pages/Login/ForgotPassword";
import LoginPage from "../pages/Login/Login";
import ResetPassword from "../pages/Login/ResetPassword";
import SignUp from "../pages/Login/SignUp";
import NotFoundPage from "../pages/NotFound";
import { setUserDetails } from "../redux/actions/authActions";
import { authenticateCurrentUser } from "../services/auth";
import history from "../services/history";

const AppContainer = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  Hub.listen(
    "auth",
    // eslint-disable-next-line no-unused-vars
    async ({ payload: { event, data } }) => {
      switch (event) {
        case "cognitoHostedUI": // set user data and get that data below
          break;
        case "signIn":
          setIsAuthenticated(true);
          break;
        case "signOut":
          setIsAuthenticated(false);
          break;
        case "cognitoHostedUI_failure":
          break;
        default:
          break;
      }
    },
    []
  );

  useEffect(() => {
    const element = document.getElementById("body-wrapper");
    const theme = sessionStorage.getItem("theme");
    if (theme === "True") {
      element.classList.add("light-theme");
    } else {
      element.classList.remove("light-theme");
    }

    authenticateCurrentUser()
      .then((data) => {
        props.setUserDetailsState(data);
        setIsAuthenticated(true);
      })
      .catch(() => {
        setIsAuthenticated(false);
        localStorage.clear();
        sessionStorage.clear();
      });
  }, []);

  return (
    <Router history={history}>
      <Switch>
        <PublicRoute
          exact
          path="/login"
          isLogin={isAuthenticated}
          component={LoginPage}
        />
        <PublicRoute
          exact
          path="/signup"
          isLogin={isAuthenticated}
          component={SignUp}
        />
        <PublicRoute
          path="/forgot-password"
          isLogin={isAuthenticated}
          component={ForgotPassword}
        />
        <PublicRoute
          path="/reset-password"
          isLogin={isAuthenticated}
          component={ResetPassword}
        />
        <Route path="/notFound" component={NotFoundPage} />

        <>
          <div className="flex-container">
            <div className="sidebar">
              <Sidebar />
            </div>
            <Layout className="h-100 layout-parent">
              <Layout.Content className="h-100 pt-3 px-4 content">
                <Switch>
                  {routes.map((route) => (
                    <PrivateRoute
                      key={route.path}
                      path={route.path}
                      exact={route.exact}
                      isLogin={isAuthenticated}
                      component={(props) => (
                        <route.layout {...props}>
                          <route.component
                            {...props}
                            isAuthenticated={isAuthenticated}
                          />
                        </route.layout>
                      )}
                    />
                  ))}
                  <PrivateRoute
                    path="*"
                    component={() => <Redirect to="/notFound" />}
                  />
                </Switch>
              </Layout.Content>
            </Layout>
          </div>
        </>
      </Switch>
    </Router>
  );
};

const mapStateToProps = ({ auth }) => {
  const isLogin = get(auth, "isLogin", false);
  return {
    isLogin,
  };
};

const mapDispatchToProps = {
  setUserDetailsState: setUserDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
