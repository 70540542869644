import { TLOGS_LOADER_START, TLOGS_LOADER_STOP, SET_TLOGS_LIST } from "./type";

const initialState = {
  isTlogsLoading: false,
  tlogsList: [],
};

const tlogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TLOGS_LOADER_START: {
      return {
        ...state,
        isTlogsLoading: true,
      };
    }

    case SET_TLOGS_LIST: {
      return {
        ...state,
        tlogsList: action.payload,
      };
    }

    case TLOGS_LOADER_STOP: {
      return {
        ...state,
        isTlogsLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default tlogsReducer;
