import { Checkbox, Select } from "antd";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { tlogGraphHelperData } from "../../helpers";
import CommonLoader from "../Loaders/loader";

const { Option } = Select;

const IWMGraph = ({ tlogParsedData, loader }) => {
  const reducerList = useSelector((state) => state);
  const themeState = reducerList?.switchThemeReducer?.switchTheme;
  const separatedDataByZone = {
    head1: tlogParsedData.filter((item) => item.zone === "Head 1"),
    head2: tlogParsedData.filter((item) => item.zone === "Head 2"),
    head3: tlogParsedData.filter((item) => item.zone === "Head 3"),
    head4: tlogParsedData.filter((item) => item.zone === "Head 4"),
  };

  const activeHeads = Object.keys(separatedDataByZone).filter(
    (key) => separatedDataByZone[key].length > 0
  );
  const numberOfActiveHeads = activeHeads.length;

  const [selectedOption, setSelectedOption] = useState("Chart 1");

  const [showHead1, setShowHead1] = useState(true);
  const [showHead2, setShowHead2] = useState(true);
  const [showHead3, setShowHead3] = useState(true);
  const [showHead4, setShowHead4] = useState(true);

  const cleanData = (dataArray) =>
    dataArray.filter((value) => value !== "" && !isNaN(value));
  const time =
    separatedDataByZone?.head1.map((item) => item.time.slice(3)) || [];
  const travelSpeeds = cleanData(
    separatedDataByZone?.head1.map((item) => item.travelSpeed) || []
  );
  const volts = cleanData(
    separatedDataByZone?.head1.map((item) => item.volts) || []
  );
  const current = cleanData(
    separatedDataByZone?.head1.map((item) => item.current) || []
  );
  const heat = cleanData(
    separatedDataByZone?.head1.map((item) => item.heat) || []
  );

  const time2 =
    separatedDataByZone?.head2.map((item) => item.time.slice(3)) || [];
  const travelSpeeds2 = cleanData(
    separatedDataByZone?.head2.map((item) => item.travelSpeed) || []
  );
  const volts2 = cleanData(
    separatedDataByZone?.head2.map((item) => item.volts) || []
  );
  const current2 = cleanData(
    separatedDataByZone?.head2.map((item) => item.current) || []
  );
  const heat2 = cleanData(
    separatedDataByZone?.head2.map((item) => item.heat) || []
  );

  const time3 =
    separatedDataByZone?.head3.map((item) => item.time.slice(3)) || [];
  const travelSpeeds3 = cleanData(
    separatedDataByZone?.head3.map((item) => item.travelSpeed) || []
  );
  const volts3 = cleanData(
    separatedDataByZone?.head3.map((item) => item.volts) || []
  );
  const current3 = cleanData(
    separatedDataByZone?.head3.map((item) => item.current) || []
  );
  const heat3 = cleanData(
    separatedDataByZone?.head3.map((item) => item.heat) || []
  );

  const time4 =
    separatedDataByZone?.head4.map((item) => item.time.slice(3)) || [];
  const travelSpeeds4 = cleanData(
    separatedDataByZone?.head4.map((item) => item.travelSpeed) || []
  );
  const volts4 = cleanData(
    separatedDataByZone?.head4.map((item) => item.volts) || []
  );
  const current4 = cleanData(
    separatedDataByZone?.head4.map((item) => item.current) || []
  );
  const heat4 = cleanData(
    separatedDataByZone?.head4.map((item) => item.heat) || []
  );

  const empty = [];

  const getGraphData1 = () => {
    let yAxisColor;
    let yAxisTitle;
    switch (selectedOption) {
      case "Travel Speed (in/min)":
        yAxisColor = "#e03540";
        yAxisTitle = "Head 1 : Travel Speed (in/min)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          travelSpeeds,
          time,
          yAxisTitle,
          yAxisColor
        );

      case "Volts (v)":
        yAxisColor = "#FFA500";
        yAxisTitle = "Head 1 : Volts (v)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          volts,
          time,
          yAxisTitle,
          yAxisColor
        );

      case "Current (A)":
        yAxisColor = "#ee82ee";
        yAxisTitle = "Head 1 : Current (A)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          current,
          time,
          yAxisTitle,
          yAxisColor
        );

      case "Heat (kJ/in)":
        yAxisColor = "#008000";
        yAxisTitle = "Head 1 : Heat (kJ/in)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          heat,
          time,
          yAxisTitle,
          yAxisColor
        );

      default:
        yAxisColor = "#0000FF";
        yAxisTitle = "Chart 1";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          empty,
          time,
          yAxisTitle,
          yAxisColor
        );
    }
  };
  const getGraphData2 = () => {
    let yAxisColor;
    let yAxisTitle;
    switch (selectedOption) {
      case "Travel Speed (in/min)":
        yAxisColor = "#e03540";
        yAxisTitle = "Head 2 : Travel Speed (in/min)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          travelSpeeds2,
          time2,
          yAxisTitle,
          yAxisColor
        );

      case "Volts (v)":
        yAxisColor = "#FFA500";
        yAxisTitle = "Head 2 : Volts (v)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          volts2,
          time2,
          yAxisTitle,
          yAxisColor
        );

      case "Current (A)":
        yAxisColor = "#ee82ee";
        yAxisTitle = "Head 2 : Current (A)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          current2,
          time2,
          yAxisTitle,
          yAxisColor
        );

      case "Heat (kJ/in)":
        yAxisColor = "#008000";
        yAxisTitle = "Head 2 : Heat (kJ/in)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          heat2,
          time2,
          yAxisTitle,
          yAxisColor
        );

      default:
        yAxisColor = "#0000FF";
        yAxisTitle = "Chart 2";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          empty,
          time2,
          yAxisTitle,
          yAxisColor
        );
    }
  };
  const getGraphData3 = () => {
    let yAxisColor;
    let yAxisTitle;
    switch (selectedOption) {
      case "Travel Speed (in/min)":
        yAxisColor = "#e03540";
        yAxisTitle = "Head 3 : Travel Speed (in/min)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          travelSpeeds3,
          time3,
          yAxisTitle,
          yAxisColor
        );

      case "Volts (v)":
        yAxisColor = "#FFA500";
        yAxisTitle = "Head 3 : Volts (v)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          volts3,
          time3,
          yAxisTitle,
          yAxisColor
        );

      case "Current (A)":
        yAxisColor = "#ee82ee";
        yAxisTitle = "Head 3 : Current (A)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          current3,
          time3,
          yAxisTitle,
          yAxisColor
        );

      case "Heat (kJ/in)":
        yAxisColor = "#008000";
        yAxisTitle = "Head 3 : Heat (kJ/in)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          heat3,
          time3,
          yAxisTitle,
          yAxisColor
        );

      default:
        yAxisColor = "#0000FF";
        yAxisTitle = "Chart 3";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          empty,
          time3,
          yAxisTitle,
          yAxisColor
        );
    }
  };
  const getGraphData4 = () => {
    let yAxisColor;
    let yAxisTitle;
    switch (selectedOption) {
      case "Travel Speed (in/min)":
        yAxisColor = "#e03540";
        yAxisTitle = "Head 4 : Travel Speed (in/min)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          travelSpeeds4,
          time4,
          yAxisTitle,
          yAxisColor
        );

      case "Volts (v)":
        yAxisColor = "#FFA500";
        yAxisTitle = "Head 4 : Volts (v)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          volts4,
          time4,
          yAxisTitle,
          yAxisColor
        );

      case "Current (A)":
        yAxisColor = "#ee82ee";
        yAxisTitle = "Head 4 : Current (A)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          current4,
          time4,
          yAxisTitle,
          yAxisColor
        );

      case "Heat (kJ/in)":
        yAxisColor = "#008000";
        yAxisTitle = "Head 4 : Heat (kJ/in)";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          heat4,
          time4,
          yAxisTitle,
          yAxisColor
        );

      default:
        yAxisColor = "#0000FF";
        yAxisTitle = "Chart 4";
        return tlogGraphHelperData.IWMGraph(
          themeState,
          empty,
          time4,
          yAxisTitle,
          yAxisColor
        );
    }
  };

  const [FirstGraph, setFirstGraph] = useState(getGraphData1());
  const [SecondGraph, setSecondGraph] = useState(getGraphData2());
  const [ThirdGraph, setThirdGraph] = useState(getGraphData3());
  const [forthGraph, setforthGraph] = useState(getGraphData4());

  useEffect(() => {
    setFirstGraph(getGraphData1());
    setSecondGraph(getGraphData2());
    setThirdGraph(getGraphData3());
    setforthGraph(getGraphData4());
  }, [selectedOption, themeState, tlogParsedData]);

  const isDataEmpty = FirstGraph.series[0].data.length === 0;
  const isDataEmpty2 = SecondGraph.series[0].data.length === 0;
  const isDataEmpty3 = ThirdGraph.series[0].data.length === 0;
  const isDataEmpty4 = forthGraph.series[0].data.length === 0;

  const colorOptions = [
    {
      value: "Travel Speed (in/min)",
      label: "Travel Speed (in/min)",
      color: "#e03540",
    },
    { value: "Volts (v)", label: "Volts (v)", color: "#FFA500" },
    { value: "Current (A)", label: "Current (A)", color: "#ee82ee" },
    {
      value: "Heat (kJ/in)",
      label: "Heat (kJ/in)",
      color: "#008000",
    },
  ];

  return (
    <>
      {loader ? (
        <div className="slogdata-loader">
          <CommonLoader />
        </div>
      ) : (
        <>
          <div className="iwm-header">
            <div className="iwm-selector">
              <Select
                value={selectedOption}
                onChange={(value) => setSelectedOption(value)}
              >
                {colorOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "10px",
                          height: "10px",
                          backgroundColor: option.color,
                          marginRight: "8px",
                        }}
                      />
                      {option.label}
                    </div>
                  </Option>
                ))}
              </Select>
            </div>
            {numberOfActiveHeads === 4 && (
              <div className="iwm-checkbox">
                <Checkbox
                  checked={showHead1}
                  onChange={() => setShowHead1(!showHead1)}
                >
                  Head 1
                </Checkbox>
                <Checkbox
                  checked={showHead2}
                  onChange={() => setShowHead2(!showHead2)}
                >
                  Head 2
                </Checkbox>
                <Checkbox
                  checked={showHead3}
                  onChange={() => setShowHead3(!showHead3)}
                >
                  Head 3
                </Checkbox>
                <Checkbox
                  checked={showHead4}
                  onChange={() => setShowHead4(!showHead4)}
                >
                  Head 4
                </Checkbox>
              </div>
            )}
          </div>
          <div className="iwm-graph">
            <div className="single-line-graph">
              {showHead1 && !isDataEmpty && (
                <Chart
                  options={FirstGraph.options}
                  series={FirstGraph.series}
                  type="line"
                  height={300}
                  width={numberOfActiveHeads === 1 ? 1260 : 620}
                  className="Graph-background"
                />
              )}
              {showHead2 && !isDataEmpty2 && (
                <Chart
                  options={SecondGraph.options}
                  series={SecondGraph.series}
                  type="line"
                  height={300}
                  width={numberOfActiveHeads === 1 ? 1260 : 620}
                  className="Graph-background"
                />
              )}
            </div>
            <div className="single-line-graph">
              {showHead3 && !isDataEmpty3 && (
                <Chart
                  options={ThirdGraph.options}
                  series={ThirdGraph.series}
                  type="line"
                  height={300}
                  width={numberOfActiveHeads === 1 ? 1260 : 620}
                  className="Graph-background"
                />
              )}
              {showHead4 && !isDataEmpty4 && (
                <Chart
                  options={forthGraph.options}
                  series={forthGraph.series}
                  type="line"
                  height={300}
                  width={numberOfActiveHeads === 1 ? 1260 : 620}
                  className="Graph-background"
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default IWMGraph;
