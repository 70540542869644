import { LeftOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Button, Drawer, List, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import SlogTab from "./Tab";
import { startZoneviewLoader } from "../../redux/zoneView/actions";
import { FETCH_ZONEVIEW_SAGA } from "../../redux/zoneView/type";

const SlogWeld = () => {
  const { t } = useTranslation();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const dispatch = useDispatch();
  const { weld_number } = useParams();
  const selectedProjectId = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_id
  );
  const selectedProjectName = useSelector(
    (state) => state?.projectReducer?.selectedProject?.project_name
  );

  useEffect(() => {
    const params = {
      project_id: selectedProjectId,
      project_name: selectedProjectName,
      weld_number: weld_number,
    };
    dispatch(startZoneviewLoader());
    dispatch({ type: FETCH_ZONEVIEW_SAGA, payload: params });
  }, [dispatch]);

  const initialZoneViewColumns = [
    {
      title: t("slogColumntitle.sl.no"),
      dataIndex: "sl_no",
      key: "name",
      width: 120,
      position: 0,
    },
    {
      title: t("slogColumntitle.status"),
      dataIndex: "status",
      key: "status",
      width: 120,
      position: 1,
    },
    {
      title: t("slogColumntitle.stationName"),
      dataIndex: "stationNumbers",
      key: "stationName",
      width: 120,
      position: 2,
    },
    {
      title: t("slogColumntitle.buyType"),
      dataIndex: "buyType",
      key: "buyType",
      width: 120,
      position: 3,
    },
    {
      title: t("slogColumntitle.torch"),
      dataIndex: "type",
      key: "type",
      width: 120,
      position: 4,
    },
    {
      title: t("slogColumntitle.pass"),
      dataIndex: "pass",
      key: "pass",
      width: 120,
      position: 5,
    },
    {
      title: t("slogColumntitle.zone"),
      dataIndex: "zone",
      key: "zone",
      width: 120,
      position: 6,
    },
    {
      title: t("slogColumntitle.weldstartTime"),
      dataIndex: "weldStartTime",
      key: "weldStartTime",
      width: 135,
      position: 7,
    },
    {
      title: t("slogColumntitle.weldTime"),
      dataIndex: "weld_time",
      key: "weldTime",
      width: 135,
      position: 8,
    },
    {
      title: t("slogColumntitle.current"),
      key: "currentA",
      position: 9,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: "current_low",
          key: "currentLow",
          width: 100,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: "current_avg",
          key: "currentAvg",
          width: 100,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: "current_high",
          key: "currentHigh",
          width: 100,
        },
      ],
    },
    {
      title: t("slogColumntitle.volt"),
      key: "voltV",
      position: 10,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: "volt_low",
          key: "voltLow",
          width: 100,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: "volt_avg",
          key: "voltAvg",
          width: 100,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: "volt_high",
          key: "voltHigh",
          width: 100,
        },
      ],
    },
    {
      title: t("slogColumntitle.travelSpeed"),
      key: "travelSpeed",
      position: 11,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: "travel_speed_low",
          key: "travelLow",
          width: 100,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: "travel_speed_avg",
          key: "travelAvg",
          width: 100,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: "travel_speed_high",
          key: "travelHigh",
          width: 100,
        },
      ],
    },
    {
      title: t("slogColumntitle.trueEnergy"),
      key: "trueEnergy",
      position: 12,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: "true_energy_low",
          key: "travelLow",
          width: 100,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: "true_energy_avg",
          key: "travelAvg",
          width: 100,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: "true_energy_high",
          key: "travelHigh",
          width: 100,
        },
      ],
    },
    {
      title: t("slogColumntitle.heat"),
      key: "heat",
      position: 13,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: "heat_low",
          key: "travelLow",
          width: 100,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: "heat_avg",
          key: "travelAvg",
          width: 100,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: "heat_high",
          key: "travelHigh",
          width: 100,
        },
      ],
    },
    {
      title: t("slogColumntitle.wireSpeed"),
      key: "wireSpeed",
      position: 14,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: "wire_speed_low",
          key: "travelLow",
          width: 100,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: "wire_speed_avg",
          key: "travelAvg",
          width: 100,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: "wire_speed_high",
          key: "travelHigh",
          width: 100,
        },
      ],
    },
    {
      title: t("slogColumntitle.oscillationWidth"),
      key: "oscillationWidth",
      position: 14,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: "oscillation_width_low",
          key: "travelLow",
          width: 100,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: "oscillation_width_avg",
          key: "travelAvg",
          width: 100,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: "oscillation_width_high",
          key: "travelHigh",
          width: 100,
        },
      ],
    },
    {
      title: t("slogColumntitle.frequency"),
      key: "frequency",
      position: 15,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: "frequency_low",
          key: "travelLow",
          width: 100,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: "frequency_avg",
          key: "travelAvg",
          width: 100,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: "frequency_high",
          key: "travelHigh",
          width: 100,
        },
      ],
    },
    {
      title: t("slogColumntitle.target"),
      key: "target",
      position: 16,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: "target_low",
          key: "travelLow",
          width: 100,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: "target_avg",
          key: "travelAvg",
          width: 100,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: "target_high",
          key: "travelHigh",
          width: 100,
        },
      ],
    },
    {
      title: t("slogColumntitle.horizontalBias"),
      key: "horizontalBias",
      position: 17,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: "horizontal_bias_low",
          key: "travelLow",
          width: 100,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: "horizontal_bias_avg",
          key: "travelAvg",
          width: 100,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: "horizontal_bias_high",
          key: "travelHigh",
          width: 100,
        },
      ],
    },
    {
      title: t("slogColumntitle.totalWireConsumed"),
      key: "totalWireConsumed",
      position: 18,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: "total_wire_consumed_low",
          key: "travelLow",
          width: 100,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: "total_wire_consumed_avg",
          key: "travelAvg",
          width: 100,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: "total_wire_consumed_high",
          key: "travelHigh",
          width: 100,
        },
      ],
    },
  ];

  const initialPassViewColumns = [
    {
      title: t("slogColumntitle.sl.no"),
      dataIndex: "sl_no",
      key: "sl_no",
      width: 120,
      position: 0,
    },
    {
      title: t("slogColumntitle.status"),
      dataIndex: "status",
      key: "status",
      width: 120,
      position: 1,
    },
    {
      title: t("slogColumntitle.stationName"),
      dataIndex: "stationNumbers",
      key: "stationNumbers",
      width: 135,
      position: 2,
    },
    {
      title: t("slogColumntitle.buyType"),
      dataIndex: "buyType",
      key: "buyType",
      width: 120,
      position: 3,
    },
    {
      title: t("slogColumntitle.torch"),
      dataIndex: "type",
      key: "type",
      width: 120,
      position: 4,
    },
    {
      title: t("slogColumntitle.pass"),
      dataIndex: "name",
      key: "pass",
      width: 120,
      position: 5,
    },
    {
      title: t("slogColumntitle.weldstartTime"),
      dataIndex: "weldStartTime",
      key: "weldStartTime",
      width: 135,
      position: 6,
    },
    {
      title: t("slogColumntitle.weldTime"),
      dataIndex: "weld_time",
      key: "weld_time",
      width: 135,
      position: 7,
    },
    {
      title: t("slogColumntitle.current"),
      key: "currentA",
      position: 8,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: ["amps", "low"],
          key: "amps_low",
          width: 20,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: ["amps", "avg"],
          key: "amps_avg",
          width: 20,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: ["amps", "high"],
          key: "amps_high",
          width: 20,
        },
      ],
    },
    {
      title: t("slogColumntitle.volt"),
      key: "voltV",
      position: 9,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: ["volts", "low"],
          key: "volts_low",
          width: 20,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: ["volts", "avg"],
          key: "volts_avg",
          width: 20,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: ["volts", "high"],
          key: "volts_high",
          width: 20,
        },
      ],
    },
    {
      title: t("slogColumntitle.travelSpeed"),
      key: "travelSpeed",
      position: 10,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: ["travel", "low"],
          key: "travel_low",
          width: 20,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: ["travel", "avg"],
          key: "travel_avg",
          width: 20,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: ["travel", "high"],
          key: "travel_high",
          width: 20,
        },
      ],
    },
    {
      title: t("slogColumntitle.trueEnergy"),
      key: "trueEnergy",
      position: 11,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: ["heat", "low"],
          key: "heat_low",
          width: 20,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: ["heat", "avg"],
          key: "heat_avg",
          width: 20,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: ["heat", "high"],
          key: "heat_high",
          width: 20,
        },
      ],
    },
    {
      title: t("slogColumntitle.heat"),
      key: "heat",
      position: 12,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: ["calculated", "low"],
          key: "calculated_low",
          width: 20,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: ["calculated", "avg"],
          key: "calculated_avg",
          width: 20,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: ["calculated", "high"],
          key: "calculated_high",
          width: 20,
        },
      ],
    },
    {
      title: t("slogColumntitle.wireSpeed"),
      key: "wireSpeed",
      position: 13,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: ["wire", "low"],
          key: "wire_low",
          width: 20,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: ["wire", "avg"],
          key: "wire_avg",
          width: 20,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: ["wire", "high"],
          key: "wire_high",
          width: 20,
        },
      ],
    },
    {
      title: t("slogColumntitle.oscillationWidth"),
      key: "oscillationWidth",
      position: 14,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: ["oscillate", "low"],
          key: "oscillate_low",
          width: 20,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: ["oscillate", "avg"],
          key: "oscillate_avg",
          width: 20,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: ["oscillate", "high"],
          key: "oscillate_high",
          width: 20,
        },
      ],
    },
    {
      title: t("slogColumntitle.frequency"),
      key: "frequency",
      position: 15,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: ["frequency", "low"],
          key: "frequency_low",
          width: 20,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: ["frequency", "avg"],
          key: "frequency_avg",
          width: 20,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: ["frequency", "high"],
          key: "frequency_high",
          width: 20,
        },
      ],
    },
    {
      title: t("slogColumntitle.target"),
      key: "target",
      position: 16,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: ["target", "low"],
          key: "target_low",
          width: 20,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: ["target", "avg"],
          key: "target_avg",
          width: 20,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: ["target", "high"],
          key: "target_high",
          width: 20,
        },
      ],
    },
    {
      title: t("slogColumntitle.horizontalBias"),
      key: "horizontalBias",
      position: 17,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: ["horizontal", "low"],
          key: "horizontal_low",
          width: 20,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: ["horizontal", "avg"],
          key: "horizontal_avg",
          width: 20,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: ["horizontal", "high"],
          key: "horizontal_high",
          width: 20,
        },
      ],
    },
    {
      title: t("slogColumntitle.totalWireConsumed"),
      key: "totalWireConsumed",
      position: 18,
      children: [
        {
          title: t("slogColumntitle.low"),
          dataIndex: ["total", "low"],
          key: "total_low",
          width: 20,
        },
        {
          title: t("slogColumntitle.avg"),
          dataIndex: ["total", "avg"],
          key: "total_avg",
          width: 20,
        },
        {
          title: t("slogColumntitle.high"),
          dataIndex: ["total", "high"],
          key: "total_high",
          width: 20,
        },
      ],
    },
  ];

  const [passVisibleColumns, setPassVisibleColumns] = useState(
    initialPassViewColumns
  );
  const [passRemovedColumns, setPassRemovedColumns] = useState([]);

  const [zoneVisibleColumns, setZoneVisibleColumns] = useState(
    initialZoneViewColumns
  );
  const [zoneRemovedColumns, setZoneRemovedColumns] = useState([]);
  const [activeTab, setActiveTab] = useState("1");

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  const removeColumn = (key, setVisible, setRemoved, visible, removed) => {
    if (visible.length <= 1) {
      notification.warning({
        message:
          "You cannot remove a column, at least one must remain in view.",
      });
      return;
    }

    const removedColumn = visible.find((col) => col.key === key);
    if (removedColumn) {
      const updatedColumns = visible.filter((col) => col.key !== key);
      setVisible(updatedColumns);
      setRemoved([...removed, removedColumn]);
    }
  };

  const addBackColumn = (key, setVisible, setRemoved, visible, removed) => {
    const addedColumn = removed.find((col) => col.key === key);
    if (addedColumn) {
      const updatedRemovedColumns = removed.filter((col) => col.key !== key);
      setRemoved(updatedRemovedColumns);

      // Find the correct position index
      let insertIndex = visible.findIndex(
        (col) => col.position > addedColumn.position
      );
      if (insertIndex === -1) {
        insertIndex = visible.length;
      }

      // Insert the column back at the correct position
      const updatedVisibleColumns = [
        ...visible.slice(0, insertIndex),
        addedColumn,
        ...visible.slice(insertIndex),
      ];
      setVisible(updatedVisibleColumns);
    }
  };

  const renderColumnOptions = (
    visibleColumns,
    removedColumns,
    setVisibleColumns,
    setRemovedColumns
  ) => (
    <List
      dataSource={[...visibleColumns, ...removedColumns]}
      renderItem={(item) => (
        <List.Item
          actions={[
            removedColumns.includes(item) ? (
              <Button
                key={`${item.key}-add`}
                onClick={() =>
                  addBackColumn(
                    item.key,
                    setVisibleColumns,
                    setRemovedColumns,
                    visibleColumns,
                    removedColumns
                  )
                }
              >
                {t("common.addBack")}
              </Button>
            ) : (
              <Button
                key={`${item.key}-remove`}
                onClick={() =>
                  removeColumn(
                    item.key,
                    setVisibleColumns,
                    setRemovedColumns,
                    visibleColumns,
                    removedColumns
                  )
                }
              >
                {t("common.remove")}
              </Button>
            ),
          ]}
        >
          {item.title}
        </List.Item>
      )}
    />
  );

  return (
    <>
      <div className="slogweld-title">
        <LeftOutlined className="leftoutlined" />
        <Link to={"/data360-home"}>
          <h2 className="slogweld-header-active">
            {t("slogColumntitle.logData")}
          </h2>
        </Link>
        <LeftOutlined className="leftoutlined" />
        <h2 className="slogweld-header">
          {t("slogColumntitle.weld")} {weld_number}
        </h2>
        <Button
          key="cancel"
          type="secondary"
          className="column-option"
          onClick={showDrawer}
        >
          <MenuFoldOutlined />
          {t("common.columnOptions")}
        </Button>
      </div>
      <SlogTab
        passVisibleColumns={passVisibleColumns}
        zoneVisibleColumns={zoneVisibleColumns}
        setActiveTab={setActiveTab}
        weld_number={weld_number}
      />
      <Drawer
        title="Column Options"
        placement="right"
        onClose={closeDrawer}
        open={drawerVisible}
        className="drawer"
        width={500}
      >
        {activeTab === "1"
          ? renderColumnOptions(
              passVisibleColumns,
              passRemovedColumns,
              setPassVisibleColumns,
              setPassRemovedColumns
            )
          : renderColumnOptions(
              zoneVisibleColumns,
              zoneRemovedColumns,
              setZoneVisibleColumns,
              setZoneRemovedColumns
            )}
      </Drawer>
    </>
  );
};

export default SlogWeld;
