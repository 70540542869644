import { colorConstants } from "../constants/global-color";

export const GraphOne = (themeState, data, categories, title, yAxisColor) => {
  const { greyColor, lightgreyColor } = colorConstants;

  const maxDataValue = Math.max(...data);
  const yAxisMaxValue = maxDataValue + maxDataValue / 2;

  const minDataValue = Math.min(...data);
  const yAxisMinValue = isNaN(minDataValue) ? -5 : 0;

  const options = {
    grid: {
      borderColor: themeState ? lightgreyColor : greyColor,
    },
    chart: {
      type: "line",
      height: 350,
      foreColor: themeState ? greyColor : lightgreyColor,
      // zoom: {
      //   enabled: true,
      // },
      // toolbar: {
      //   show: true,
      //   tools: {
      //     download: true,
      //     selection: false,
      //     zoom: false,
      //     zoomin: true,
      //     zoomout: true,
      //     pan: false,
      //     reset: true,
      //   },
      // },
    },
    stroke: {
      curve: "straight",
      width: [2, 2],
    },
    xaxis: {
      categories: categories,
      // min: Math.min(...categories),
      // max: Math.max(...categories),
      tickAmount: 12,
      fillColor: yAxisColor,
    },
    yaxis: [
      {
        title: {
          text: title,
          style: {
            color: yAxisColor,
          },
        },
        min: yAxisMinValue,
        max: yAxisMaxValue,
        tickAmount: 6,
        fillColor: yAxisColor,
        // labels: {
        //   style: {
        //     colors: yAxisColor,
        //   },
        // },
      },
    ],
    markers: {
      size: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme: "dark",
    },
    colors: [yAxisColor],
  };

  const series = [
    {
      name: title,
      data: data,
      color: yAxisColor,
    },
  ];

  return { series, options };
};

export const GraphTwo = (themeState, volts, categories, title, yAxisColor) => {
  const { greyColor, lightgreyColor } = colorConstants;

  const maxDataValue = Math.max(...volts);
  const yAxisMaxValue = maxDataValue + maxDataValue / 2;

  const minDataValue = Math.min(...volts);
  const yAxisMinValue = isNaN(minDataValue) ? -5 : 0;

  const options = {
    grid: {
      borderColor: themeState ? lightgreyColor : greyColor,
    },
    chart: {
      type: "line",
      height: 350,
      foreColor: themeState ? greyColor : lightgreyColor,
    },
    stroke: {
      curve: "straight",
      width: [2, 2],
    },
    xaxis: {
      categories: categories,
      tickAmount: 12,
      fillColor: yAxisColor,
    },
    yaxis: [
      {
        title: {
          text: title,
          style: {
            color: yAxisColor,
          },
        },
        min: yAxisMinValue,
        max: yAxisMaxValue,
        tickAmount: 6,
        fillColor: yAxisColor,
      },
    ],
    markers: {
      size: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme: "dark",
    },
    colors: [yAxisColor],
  };

  const series = [
    {
      name: title,
      data: volts,
      color: yAxisColor,
    },
  ];

  return { series, options };
};

export const GraphThree = (
  themeState,
  totalWireConsumed,
  categories,
  title,
  yAxisColor
) => {
  const { greyColor, lightgreyColor } = colorConstants;

  const maxDataValue = Math.max(...totalWireConsumed);
  const yAxisMaxValue = maxDataValue + maxDataValue / 2;

  const minDataValue = Math.min(...totalWireConsumed);
  const yAxisMinValue = isNaN(minDataValue) ? -5 : 0;

  const options = {
    grid: {
      borderColor: themeState ? lightgreyColor : greyColor,
    },
    chart: {
      type: "line",
      height: 350,
      foreColor: themeState ? greyColor : lightgreyColor,
    },
    stroke: {
      curve: "straight",
      width: [2, 2],
    },
    xaxis: {
      categories: categories,
      tickAmount: 12,
      fillColor: yAxisColor,
    },
    yaxis: [
      {
        title: {
          text: title,
          style: {
            color: yAxisColor,
          },
        },
        min: yAxisMinValue,
        max: yAxisMaxValue,
        tickAmount: 6,
        fillColor: yAxisColor,
      },
    ],
    markers: {
      size: 0, // Hide the markers
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme: "dark",
    },
    colors: [yAxisColor],
  };

  const series = [
    {
      name: title,
      data: totalWireConsumed,
      color: yAxisColor,
    },
  ];

  return { series, options };
};

export const GraphFour = (
  themeState,
  trueEnergy,
  categories,
  title,
  yAxisColor
) => {
  const { greyColor, lightgreyColor } = colorConstants;

  const maxDataValue = Math.max(...trueEnergy);
  const yAxisMaxValue = maxDataValue + maxDataValue / 2;

  const minDataValue = Math.min(...trueEnergy);
  const yAxisMinValue = isNaN(minDataValue) ? -5 : 0;

  const options = {
    grid: {
      borderColor: themeState ? lightgreyColor : greyColor,
    },
    chart: {
      type: "line",
      height: 350,
      foreColor: themeState ? greyColor : lightgreyColor,
    },
    stroke: {
      curve: "straight",
      width: [2, 2],
    },
    xaxis: {
      categories: categories,
      tickAmount: 12,
      fillColor: yAxisColor,
    },
    yaxis: [
      {
        title: {
          text: title,
          style: {
            color: yAxisColor,
          },
        },
        min: yAxisMinValue,
        max: yAxisMaxValue,
        tickAmount: 6,
        fillColor: yAxisColor,
      },
    ],
    markers: {
      size: 0, // Hide the markers
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme: "dark",
    },
    colors: [yAxisColor],
  };

  const series = [
    {
      name: title,
      data: trueEnergy,
      color: yAxisColor,
    },
  ];

  return { series, options };
};

export const GraphFive = (
  themeState,
  horizontalBias,
  categories,
  title,
  yAxisColor
) => {
  const { greyColor, lightgreyColor } = colorConstants;

  const maxDataValue = Math.max(...horizontalBias);
  const yAxisMaxValue = maxDataValue + maxDataValue / 2;

  const minDataValue = Math.min(...horizontalBias);
  const yAxisMinValue = isNaN(minDataValue) ? -5 : 0;

  const options = {
    grid: {
      borderColor: themeState ? lightgreyColor : greyColor,
    },
    chart: {
      type: "line",
      height: 350,
      foreColor: themeState ? greyColor : lightgreyColor,
    },
    stroke: {
      curve: "straight",
      width: [2, 2],
    },
    xaxis: {
      categories: categories,
      tickAmount: 12,
      fillColor: yAxisColor,
    },
    yaxis: [
      {
        title: {
          text: title,
          style: {
            color: yAxisColor,
          },
        },
        min: yAxisMinValue,
        max: yAxisMaxValue,
        tickAmount: 6,
        fillColor: yAxisColor,
      },
    ],
    markers: {
      size: 0, // Hide the markers
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme: "dark",
    },
    colors: [yAxisColor],
  };

  const series = [
    {
      name: title,
      data: horizontalBias,
      color: yAxisColor,
    },
  ];

  return { series, options };
};

export const GraphSix = (
  themeState,
  oscillationWidth,
  categories,
  title,
  yAxisColor
) => {
  const { greyColor, lightgreyColor } = colorConstants;

  const maxDataValue = Math.max(...oscillationWidth);
  const yAxisMaxValue = maxDataValue + maxDataValue / 2;

  const minDataValue = Math.min(...oscillationWidth);
  const yAxisMinValue = isNaN(minDataValue) ? -5 : 0;

  const options = {
    grid: {
      borderColor: themeState ? lightgreyColor : greyColor,
    },
    chart: {
      type: "line",
      height: 350,
      foreColor: themeState ? greyColor : lightgreyColor,
    },
    stroke: {
      curve: "straight",
      width: [2, 2],
    },
    xaxis: {
      categories: categories,
      tickAmount: 12,
      fillColor: yAxisColor,
    },
    yaxis: [
      {
        title: {
          text: title,
          style: {
            color: yAxisColor,
          },
        },
        min: yAxisMinValue,
        max: yAxisMaxValue,
        tickAmount: 6,
        fillColor: yAxisColor,
      },
    ],
    markers: {
      size: 0, // Hide the markers
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme: "dark",
    },
    colors: [yAxisColor],
  };

  const series = [
    {
      name: title,
      data: oscillationWidth,
      color: yAxisColor,
    },
  ];

  return { series, options };
};

export const IWMGraph = (
  themeState,
  oscillationWidth,
  categories,
  title,
  yAxisColor
) => {
  const { greyColor, lightgreyColor } = colorConstants;

  const maxDataValue = Math.max(...oscillationWidth);
  const yAxisMaxValue = maxDataValue + maxDataValue / 2;

  const minDataValue = Math.min(...oscillationWidth);
  const yAxisMinValue = isNaN(minDataValue) ? -5 : 0;

  const options = {
    grid: {
      borderColor: themeState ? lightgreyColor : greyColor,
    },
    chart: {
      type: "line",
      height: 350,
      foreColor: themeState ? greyColor : lightgreyColor,
    },
    stroke: {
      curve: "straight",
      width: [2, 2],
    },
    xaxis: {
      categories: categories,
      tickAmount: 12,
      fillColor: yAxisColor,
    },
    yaxis: [
      {
        title: {
          text: title,
          style: {
            color: yAxisColor,
          },
        },
        min: yAxisMinValue,
        max: yAxisMaxValue,
        tickAmount: 6,
        fillColor: yAxisColor,
      },
    ],
    markers: {
      size: 0, // Hide the markers
    },
    tooltip: {
      shared: true,
      intersect: false,
      theme: "dark",
    },
    colors: [yAxisColor],
  };

  const series = [
    {
      name: title,
      data: oscillationWidth,
      color: yAxisColor,
    },
  ];

  return { series, options };
};
