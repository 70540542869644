const FETCH_ZONEVIEW_SAGA = "@@zoneview/fetchZoneViewSaga";
const SET_ZONEVIEW_LIST = "@@zoneview/setZoneviewList";
const ZONEVIEW_LOADER_START = "@@zoneview/startLoader";
const ZONEVIEW_LOADER_STOP = "@@zoneview/stopLoader";

export {
  FETCH_ZONEVIEW_SAGA,
  SET_ZONEVIEW_LIST,
  ZONEVIEW_LOADER_START,
  ZONEVIEW_LOADER_STOP,
};
