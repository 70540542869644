import {
  SET_ZONEVIEW_LIST,
  ZONEVIEW_LOADER_START,
  ZONEVIEW_LOADER_STOP,
} from "./type";

const initialState = {
  isZoneviewLoding: false,
  zoneviewList: [],
};

const zoneviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ZONEVIEW_LOADER_START: {
      return {
        ...state,
        isZoneviewLoding: true,
      };
    }

    case SET_ZONEVIEW_LIST: {
      return {
        ...state,
        zoneviewList: action.payload,
      };
    }

    case ZONEVIEW_LOADER_STOP: {
      return {
        ...state,
        isZoneviewLoding: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default zoneviewReducer;
