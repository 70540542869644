const commonConstant = {
  maxFormFieldLength: 40,
};

const inputTypes = {
  input: "input",
  date: "date",
  switch: "switch",
  select: "select",
  number: "number",
};

const carouselConfig = [
  { width: 550, itemsToShow: 3, itemsToScroll: 3 },
  { width: 768, itemsToShow: 5, itemsToScroll: 5 },
  { width: 1200, itemsToShow: 6, itemsToScroll: 6 },
  { width: 1500, itemsToShow: 6, itemsToScroll: 6 },
];

const headerTittle = "Data-360";

const pageSizeOptions = ["30", "50", "100"];

const ZonePageSizeOptions = ["30", "50", "100"];

const defaultPageSize = 30;

const ModalpageSizeOptions = ["50", "100"];

const PipeDatapageSizeOptions = ["50", "100"];

let colorPalette = [
  "red",
  "blue",
  "green",
  "purple",
  "orange",
  "darkred",
  "lightred",
  "beige",
  "darkblue",
  "darkgreen",
  "cadetblue",
  "darkpurple",
  "#123456",
  "pink",
  "lightblue",
  "lightgreen",
  "gray",
  "black",
  "lightgray",
];

const mapViewModes = {
  satellite: "satellite",
  street: "street",
};

export {
  commonConstant,
  pageSizeOptions,
  headerTittle,
  inputTypes,
  carouselConfig,
  defaultPageSize,
  ModalpageSizeOptions,
  PipeDatapageSizeOptions,
  colorPalette,
  ZonePageSizeOptions,
  mapViewModes,
};
