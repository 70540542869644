const FETCH_SLOGS_SAGA = "@@slogs/fetchSlogsSaga";
const SET_SLOGS_LIST = "@@slogs/setSlogsList";
const SLOGS_LOADER_START = "@@slogs/startLoader";
const SLOGS_LOADER_STOP = "@@slogs/stopLoader";
const FILTERED_TABLE_DATA = "@@slogs/filteredTableData";

export {
  FETCH_SLOGS_SAGA,
  SET_SLOGS_LIST,
  SLOGS_LOADER_START,
  SLOGS_LOADER_STOP,
  FILTERED_TABLE_DATA,
};
