const displayFields = [
  {
    name: "common.clientname",
    value: "customer_name",
  },
  {
    name: "common.technician",
    value: "user_id",
  },
  {
    name: "common.weldingsystem",
    value: "welding_system",
  },
  {
    name: "common.weldAssingmentType",
    value: "welding_assignment_type",
  },
  {
    name: "common.iwm",
    value: "iwm",
  },
  {
    name: "common.numberofStation",
    value: "num_stations",
  },
  {
    name: "common.startDate",
    value: "start_date",
  },
  {
    name: "common.endDate",
    value: "end_date",
  },
  {
    name: "common.completedWelds",
    value: "welds_completed",
  },
  {
    name: "common.wallthickness",
    value: "pipe_thickness",
  },
  {
    name: "common.pipediameter",
    value: "pipe_diameter",
  },
  {
    name: "common.pipeMaterial",
    value: "pipe_material",
  },
];

export { displayFields };
